import { Skeleton } from '@chakra-ui/react';
import { useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { generatePath, resolvePath, useNavigate, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  FundraisingType,
  useCreateFundraisingMutation,
  useGetCompanyQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';

import { privateFundraisingParametersDefaults } from '../../utils';
import { IPrivateFundraisingParametersForm } from '../types';
import { NewPrivateFundraisingInformations } from './NewPrivateFundraisingInformations';

type Props = unknown;

/**
 * NewPrivateFundraisingCreate.
 * First step of private fundraising funnel.
 *
 * @returns {JSX.Element}
 */
export function NewPrivateFundraisingCreate(): JSX.Element {
  const navigate = useNavigate();

  const { companyId = '', opportunityId = null } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const opportunityReq = useGetOperationQuery({
    variables: { operationId: opportunityId || '' },
    skip: !opportunityId,
  });
  const opportunity = opportunityReq.data?.operation;

  const isWorkspaceLoaded = !!company;

  const [createFundraising, { loading }] = useCreateFundraisingMutation();

  const defaultValues = useMemo(() => {
    return company && privateFundraisingParametersDefaults(company, opportunity);
  }, [company, opportunity]);

  const handleFormSubmit: SubmitHandler<IPrivateFundraisingParametersForm> = (data): void => {
    if (company) {
      const assetId = data.asset.value !== data.asset.type ? data.asset.value : undefined;
      const assetType = data.asset.type;

      const selectedFiscalAdvantages =
        assetType === AssetType.BOND
          ? []
          : data.allowedFiscalAdvantages.map((fiscalAdvantage) => fiscalAdvantage.value);

      const redirectPrefix = opportunityId ? '../../' : '';

      createFundraising({
        variables: {
          createFundraisingInput: {
            name: data.name,
            companyId,
            sharePrice: data.sharePrice,
            subscriptionPeriod: data.subscriptionPeriod.value,
            assetId,
            assetType,
            createBondAsset: data.createBondAsset,
            allowedFiscalAdvantages: selectedFiscalAdvantages,
            type: FundraisingType.PRIVATE,
            transactionDebitDate: data?.isRelatedToSecondary ? data.transactionDebitDate : null,
            opportunityId,
            proofOfFundsThreshold: data.proofOfFundsThreshold,
          },
        },
        onCompleted: (data) => {
          if (company) {
            /* ** Remove /create from history when we go back ** */
            window.history.replaceState(
              null,
              '',
              generatePath(routes.company.newFundraising.private.edit.full, {
                companyId,
                operationId: data.createFundraising.id,
              }),
            );
            navigate(
              redirectPrefix +
                resolvePath(
                  routes.company.newFundraising.private.edit.documentation.href,
                  generatePath(routes.company.newFundraising.private.edit.href, {
                    operationId: data.createFundraising.id,
                  }),
                ).pathname,
              { relative: 'path' },
            );
          }
        },
      });
    }
  };

  return (
    <Skeleton isLoaded={isWorkspaceLoaded} w="full">
      <NewPrivateFundraisingInformations
        defaultValues={defaultValues}
        isLoading={loading}
        onSubmit={handleFormSubmit}
      />
    </Skeleton>
  );
}

export type NewPrivateFundraisingCreateProps = Props;
