import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Card, CardBody, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  CompanyType,
  useGetAssetPricesByCompanyQuery,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, ILineChartData, SimpleLineChart } from '@blockpulse3/ui/commons';

import { SettingsCompanyAssetPriceTable } from './SettingsCompanyAssetPriceTable';

type Props = {
  onOpen: () => void;
};

export function SettingsCompanyAssetPrice({ onOpen }: Props): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq.data?.company;

  const { data, loading, error } = useGetAssetPricesByCompanyQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  if (!company?.type || ![CompanyType.SPV, CompanyType.TOKENIZED].includes(company?.type))
    return null;

  const formattedDatas: ILineChartData[] =
    data?.getAssetPricesByCompany?.map((assetPrice) => ({
      x: dayjs(assetPrice.date).valueOf(),
      y: assetPrice.price,
    })) || [];

  return (
    <Stack
      alignItems="flex-start"
      direction={{ base: 'column', lg: 'row' }}
      spacing={{ base: 5, lg: 8 }}
    >
      <HStack alignItems="center" flexBasis={{ lg: 250 }}>
        <Text fontSize="lg" fontWeight="medium">
          {t('Valuation', { nb: formattedDatas?.length || 0 })}
        </Text>
      </HStack>
      <Skeleton isLoaded={!loading} maxWidth={{ lg: '3xl' }} width="full">
        {error ? (
          <ErrorQueryCard h="100px" maxW={{ lg: '3xl' }} w="full" />
        ) : (
          <Card maxW={{ lg: '3xl' }} variant="divider-bottom">
            <CardBody>
              <Stack spacing="4">
                {formattedDatas.length && (
                  <Box h={200}>
                    <SimpleLineChart data={formattedDatas} />
                  </Box>
                )}
                <SettingsCompanyAssetPriceTable />
                <Button leftIcon={<AddIcon />} variant="secondary" w="100%" onClick={onOpen}>
                  {t('AddAssetPrice')}
                </Button>
              </Stack>
            </CardBody>
          </Card>
        )}
      </Skeleton>
    </Stack>
  );
}
