import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  useGetOptionAssetsQuery,
  useRegisterShareTransferRegisterMutation,
} from '@blockpulse3/graphql/hooks';
import { useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

import { CapTablePreview } from './CapTablePreview';
import { ShareTransferRegisterPreview } from './ShareTransferRegisterPreview';

type Props = unknown;

export function RepatriationShareTransferRegisterSummary(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { companyId = '' } = useParams();
  const navigate = useNavigate();

  const optionAssetReq = useGetOptionAssetsQuery({ variables: { companyId } });
  const [registerShareTransferRegister] = useRegisterShareTransferRegisterMutation();

  const optionAssets = optionAssetReq?.data?.getOptionAssets || [];

  const handleStepCancel = (): void => {
    if (optionAssets.length) {
      return navigate('../' + routes.company.repatriation.grants.href);
    }
    navigate('../' + routes.company.repatriation.import.href);
  };

  const handleStepSubmit = (): void => {
    setIsLoading(true);
    registerShareTransferRegister({
      variables: { companyId },
      onCompleted: () => {
        successToast({ title: t('ShareholdingRepatriationSuccess') });
        navigate('../../' + routes.company.captable.href);
        setIsLoading(false);
      },
      onError: () => {
        errorToast({ title: t('ShareholdingRepatriationError') });
        setIsLoading(false);
      },
    });
  };

  return (
    <Card variant="divider-top" width="full">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{t('Summary')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        <Tabs isLazy>
          <TabList>
            <Tab>{t('CapitalizationTable')}</Tab>
            <Tab>{t('ShareTransferRegister')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <CapTablePreview />
            </TabPanel>
            <TabPanel px="0">
              <ShareTransferRegisterPreview />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button isDisabled={isLoading} variant="secondary" w="full" onClick={handleStepCancel}>
          {t('Back')}
        </Button>
        <Button isDisabled={isLoading} isLoading={isLoading} w="full" onClick={handleStepSubmit}>
          {t('Validate')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type RepatriationShareTransferRegisterSummaryProps = Props;
