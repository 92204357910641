import { noop } from '@blockpulse3/data/shared';
import { AssetEntityInfosFragment, AssetInfosFragment } from '@blockpulse3/graphql/hooks';
import { ScrollableHStack } from '@blockpulse3/ui/commons';

import { AssetCard } from './AssetCard';

type Props = {
  stocks: AssetEntityInfosFragment[];
  bonds: AssetInfosFragment[];
  options: AssetInfosFragment[];
  handleStockClick: () => void;
  handleOptionClick: (asset: AssetInfosFragment | AssetEntityInfosFragment) => void;
  handleBondClick: (asset: AssetInfosFragment | AssetEntityInfosFragment) => void;
};

export function AssetAndOptionCards({
  stocks,
  bonds,
  options,
  handleStockClick = noop,
  handleOptionClick = noop,
  handleBondClick = noop,
}: Props): JSX.Element {
  return (
    <ScrollableHStack overflow="scroll" scrollAmount={200}>
      {stocks.map((stock) => (
        <AssetCard key={stock.id} asset={stock} onClick={handleStockClick} />
      ))}
      {options.map((option) => (
        <AssetCard key={option.id} asset={option} onClick={handleOptionClick} />
      ))}
      {bonds.map((bond) => (
        <AssetCard key={bond.id} asset={bond} onClick={handleBondClick} />
      ))}
    </ScrollableHStack>
  );
}
