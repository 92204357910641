import { Button, HStack, Heading, Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { CashIcon, PlusIcon } from '@heroicons/react/outline';
import { generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import {
  AssetEntityInfosFragment,
  AssetInfosFragment,
  OperationType,
  useGetBondAssetsQuery,
  useGetCompanyQuery,
  useGetOptionAssetsQuery,
  useGetStockAssetsQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useManagerRole } from '@blockpulse3/web-client/auth';

import { AssetAndOptionCards } from './AssetAndOptionCards';
import { AssetAndOptionTable } from './AssetAndOptionTable';

type Props = {
  displayType?: 'table' | 'cards';
  showOptions?: boolean;
};

export function AssetAndOptionList({
  displayType = 'table',
  showOptions = true,
}: Props): JSX.Element | null {
  const t = useTranslations();

  const navigate = useNavigate();
  const location = useLocation();
  const { companyId = '' } = useParams();
  const isFeatureOptionPool = isFeatureEnabled('optionPool');
  const isRepatriationAuthorized = useManagerRole({ companyId });

  const { data: companyData } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const hasCaptable =
    !!companyData?.company?.capTable?.stockTotalSupply &&
    !!companyData?.company?.capTable?.fullyDilutedTotalSupply;

  const {
    data: stockData,
    loading: stockLoading,
    error: stockError,
  } = useGetStockAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId || !hasCaptable,
  });

  const {
    data: optionData,
    loading: optionLoading,
    error: optionError,
  } = useGetOptionAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !showOptions || !companyId || !isFeatureOptionPool || !hasCaptable,
  });

  const {
    data: bondData,
    loading: bondLoading,
    error: bondError,
  } = useGetBondAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId || !hasCaptable,
  });

  if (
    !isFeatureOptionPool ||
    (!stockData?.getStockAssets?.length &&
      showOptions &&
      !optionData?.getOptionAssets?.length &&
      !isRepatriationAuthorized)
  ) {
    return null;
  }

  if (stockError || (showOptions && optionError) || bondError) {
    return (
      <Stack spacing="4">
        <Heading size="md">{showOptions ? t('AssetsAndPools') : t('Asset', { nb: 2 })}</Heading>
        <ErrorQueryCard h="200px" />
      </Stack>
    );
  }

  if (
    stockLoading ||
    (showOptions && optionLoading) ||
    bondLoading ||
    (hasCaptable && !stockData)
  ) {
    return (
      <Stack spacing="4">
        <Heading size="md">{showOptions ? t('AssetsAndPools') : t('Asset', { nb: 2 })}</Heading>
        <HStack overflowX="hidden" spacing="4">
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
          <Skeleton minH="150px" minW="180px"></Skeleton>
        </HStack>
      </Stack>
    );
  }

  const currentStocks = stockData?.getStockAssets || [];
  const currentOptions = optionData?.getOptionAssets || [];
  const currentBonds = bondData?.getBondAssets || [];
  const isEmpty =
    !hasCaptable && currentStocks.length === 0 && (!showOptions || currentOptions.length === 0);
  const isInCaptable = matchPath(
    routes.company.href + '/' + routes.company.captable.href + '/*',
    location.pathname,
  );

  const handleStockClick = (): void => {
    if (isInCaptable) {
      navigate('..', { relative: 'path' });
    } else {
      navigate(routes.company.captable.href);
    }
  };

  const handleRepatriationClick = (): void => {
    navigate(routes.company.repatriation.href);
  };

  const handleOptionClick = (asset: AssetInfosFragment | AssetEntityInfosFragment): void => {
    if (companyId) {
      navigate(generatePath(routes.company.option.href, { assetId: asset.id }));
    }
  };

  const handleBondClick = (asset: AssetInfosFragment | AssetEntityInfosFragment): void => {
    if (companyId && 'operation' in asset && asset.operation?.id) {
      const pathPrefix = isInCaptable ? '../' : '';
      const routeSlug =
        asset.operation.type === OperationType.OPPORTUNITY ? 'opportunity' : 'fundraising';
      navigate(
        pathPrefix +
          generatePath(routes.company[routeSlug].href, {
            companyId,
            operationId: asset.operation.id,
          }),
      );
    }
  };

  const RenderAssetsAndOptions = (): JSX.Element => {
    switch (displayType) {
      case 'cards':
        return (
          <AssetAndOptionCards
            bonds={currentBonds}
            handleBondClick={handleBondClick}
            handleOptionClick={handleOptionClick}
            handleStockClick={handleStockClick}
            options={currentOptions}
            stocks={currentStocks}
          />
        );
      case 'table':
        return (
          <AssetAndOptionTable
            bonds={currentBonds}
            handleBondClick={handleBondClick}
            handleOptionClick={handleOptionClick}
            handleStockClick={handleStockClick}
            options={currentOptions}
            showOptions={showOptions}
            stocks={currentStocks}
          />
        );
    }
  };

  return (
    <Stack spacing="4">
      <Stack
        alignItems={{ base: 'flex-start', md: 'center' }}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">{showOptions ? t('AssetsAndPools') : t('Asset', { nb: 2 })}</Heading>
        {showOptions && !isEmpty && (
          <Button size="sm" variant="light" onClick={handleStockClick}>
            {t('ManageShareholding')}
          </Button>
        )}
      </Stack>
      {isEmpty ? (
        <Stack layerStyle="emptyState">
          <Icon as={CashIcon} boxSize="40px" color="gray.500" />
          <Text>{t('NoAsset')}</Text>
          {showOptions && (
            <Stack>
              <Button
                leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                size="sm"
                variant="light"
                w="fit-content"
                onClick={handleRepatriationClick}
              >
                {t('RepatriateShareholding')}
              </Button>
            </Stack>
          )}
        </Stack>
      ) : (
        <RenderAssetsAndOptions />
      )}
    </Stack>
  );
}

export type AssetAndOptionListProps = Props;
