import { Button, Icon, Skeleton, Stack, Text } from '@chakra-ui/react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { useCallback, useState } from 'react';
import { Link as ReactRouterLink, generatePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Exceptions, SubscriptionPaymentMethod, routes } from '@blockpulse3/data/shared';
import {
  HoldingMethod,
  MemberRole,
  OperationInvestStatus,
  OperationStatus,
  RepresentativeRole,
  SecondaryMarketType,
  SubscriptionSide,
  SubscriptionStepStatus,
  SubscriptionStepType,
  useGetBankDistributionBySubscriptionQuery,
  useGetCompanyQuery,
  useGetSubscriptionQuery,
  useQueueCreditTransferTransactionMutation,
  useValidateTransactionMutation,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  Progress,
  ProgressStep,
  WarningCard,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useStepStatusMap } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser, useManagerRole } from '@blockpulse3/web-client/auth';

import { ConsultIdentityVerification } from '../OperationPanel/OperationPanelProgressSteps/IdentityVerificationStep/ConsultIdentityVerification';
import { ResetDocumentSignature } from '../SubscriptionPanel/SubscriptionPanelProgressSteps/DocumentSignatureStep/ResetDocumentSignature';

type Props = unknown;

/**
 * TransactionPanelProgressSteps.
 * Progress step logic section of the transaction side panel.
 *
 * @returns {JSX.Element}
 */
export function TransactionPanelProgressSteps(): JSX.Element {
  const t = useTranslations();

  const [isSigningDisabled, setIsSigningDisabled] = useState<boolean>(false);

  const { user: authUser } = useAuthUser();
  const { subscriptionId = '' } = useParams();

  const isUserComplianceOfficer = useManagerRole({ subscriptionId }, [
    MemberRole.OWNER,
    MemberRole.ADMIN,
    MemberRole.COMPLIANCE_OFFICER,
  ]);

  /* ** Toasts ** */
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { data, loading, error, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });
  const subscription = data?.subscription;

  const companyReq = useGetCompanyQuery({
    variables: { companyId: subscription?.operation?.company?.id || '' },
    skip: !subscription?.operation?.company?.id,
  });
  const company = companyReq.data?.company;

  const [stepStatusMap, steps] = useStepStatusMap(subscription?.data?.mergedSteps);

  const [validateTransaction, { loading: validateLoading }] = useValidateTransactionMutation();
  const [queueCreditTransferTransaction, { loading: queueTransferLoading }] =
    useQueueCreditTransferTransactionMutation();

  const bankDistributionReq = useGetBankDistributionBySubscriptionQuery({
    variables: { subscriptionId },
    skip:
      !subscription ||
      subscription.paymentMethod !== SubscriptionPaymentMethod.QUEUED_CREDIT_TRANSFER,
  });
  const bankDistribution = bankDistributionReq.data?.getBankDistributionBySubscription;

  const IdentityVerificationStep = useCallback(
    ({ isSeller }: { isSeller?: boolean }): JSX.Element | null => (
      <ConsultIdentityVerification isSeller={isSeller} subscriptionId={subscriptionId} />
    ),
    [subscriptionId],
  );

  if (loading || error || !subscription?.operation) {
    return (
      <Stack px="4" spacing="4">
        <Text color="gray.600" fontWeight="600">
          {t('StepsToValidate')}
        </Text>
        <Stack spacing="2">
          <Skeleton h="30px" w="60%" />
          <Skeleton h="30px" w="80%" />
          <Skeleton h="30px" w="65%" />
          <Skeleton h="30px" w="70%" />
        </Stack>
      </Stack>
    );
  }

  const balance = subscription?.bankVirtualIban?.balance || 0;

  const hasAlternativeHoldingMethod = [HoldingMethod.PEA, HoldingMethod.PEA_PME].includes(
    subscription?.holdingMethod,
  );

  const subscriptionStepTypes =
    subscription?.data.mergedSteps?.map((step) => {
      return step.type;
    }) || [];

  const amountDue = subscription?.amountDue;
  const isWithdrawalMarket =
    subscription?.operation?.settings?.secondaryMarketType ===
    SecondaryMarketType.WITHDRAWAL_MARKET;

  const isUserAuthorized =
    isUserComplianceOfficer ||
    company?.companyRepresentative.some(
      (rep) =>
        [
          RepresentativeRole.PRESIDENT,
          RepresentativeRole.COMPANY_MANAGER,
          RepresentativeRole.SIGNER,
        ].includes(rep.role) &&
        rep.representativeIdentity.individualIdentity?.id === authUser?.individualIdentity?.id,
    );
  const shouldDisplayValidateSubscription =
    subscription?.investStatus === OperationInvestStatus.COMPLETED && isUserAuthorized;

  /* ** On validate subscription button click */
  const handleValidateTransaction = (): void => {
    validateTransaction({
      variables: {
        validateTransactionInput: {
          subscriptionId,
        },
      },
      onCompleted: (data) => {
        if (data.validateTransaction?.signingUrl) {
          window.open(data.validateTransaction.signingUrl, '_self');
        } else {
          successToast({ title: t('DocumentSentToSignatory') });
          setIsSigningDisabled(true);
        }
        refetch();
      },
      onError: (err: unknown) => {
        const error = err as Error;
        if (error.message === Exceptions.ODMWithPendingSignature) {
          errorToast({
            title: t('ValidationRejectedODMWithPendingSignature'),
          });
        } else {
          errorToast({ title: t('TransactionValidationError') });
        }
      },
    });
  };

  const handleQueueDirectDebit = (): void => {
    queueCreditTransferTransaction({
      variables: {
        queueCreditTransferTransactionInput: {
          subscriptionId,
        },
      },
      onCompleted: () => {
        successToast({ title: t('PendingQueuedPaymentSuccess') });
        refetch();
      },
    });
  };

  const PaymentStep = (): JSX.Element | null => {
    if (!amountDue) return null;

    if (
      isUserAuthorized &&
      isWithdrawalMarket &&
      stepStatusMap[SubscriptionStepType.DOCUMENT_SIGNATURE] === SubscriptionStepStatus.VALID
    ) {
      if (stepStatusMap[SubscriptionStepType.PAYMENT] === SubscriptionStepStatus.NONE) {
        return (
          <Button
            isLoading={queueTransferLoading}
            size="sm"
            variant="secondary"
            onClick={handleQueueDirectDebit}
          >
            {t('AddTransactionToQueue')}
          </Button>
        );
      }
      if (
        stepStatusMap[SubscriptionStepType.PAYMENT] === SubscriptionStepStatus.PENDING &&
        bankDistribution
      ) {
        const distributionLink =
          '../../../' +
          generatePath(routes.company.distribution.href, { distributionId: bankDistribution.id });
        return (
          <Button
            as={ReactRouterLink}
            relative="path"
            rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
            size="sm"
            to={distributionLink}
            variant="secondary"
          >
            {t('PendingQueuedLink')}
          </Button>
        );
      }
    }

    if (stepStatusMap[SubscriptionStepType.PAYMENT] === SubscriptionStepStatus.VALID) {
      return (
        <>
          <Text color="gray.800" fontSize="xs" fontWeight="600">
            {t('AmountReceived', {
              amount: formatNumberCurrency(balance || amountDue),
            })}
          </Text>
          {!!balance && balance > amountDue && (
            <WarningCard
              h="8"
              py="1"
              title={t('OverpaidByAmount', {
                amount: formatNumberCurrency(balance - amountDue),
              })}
            />
          )}
        </>
      );
    } else if (balance) {
      return (
        <Text color="gray.800" fontSize="xs" fontWeight="600">
          {t('AmountReceived', {
            amount: formatNumberCurrency(balance),
          })}
        </Text>
      );
    }
    return null;
  };

  const DocumentSignatureStep = (): JSX.Element | null => {
    if (
      stepStatusMap[SubscriptionStepType.DOCUMENT_SIGNATURE] !== SubscriptionStepStatus.NONE &&
      subscription &&
      ![OperationStatus.CLOSED, OperationStatus.REVIEWED, OperationStatus.FINALIZED].includes(
        subscription.operation.status,
      )
    ) {
      return (
        <ResetDocumentSignature
          operationDocumentType={subscription.operation.transactionDocumentType}
          subscriptionId={subscriptionId}
        />
      );
    }
    return null;
  };

  return (
    <Stack px="4" spacing="4">
      <Text color="gray.600" fontWeight="600">
        {t('StepsToValidate')}
      </Text>
      <Progress direction="column">
        <ProgressStep
          key={0}
          index={0}
          textProps={{ fontSize: 'sm', fontWeight: '500', color: 'gray.500' }}
          title={t('AccountCreation')}
          status={
            subscription?.sellerIdentity?.isSignedUp
              ? SubscriptionStepStatus.VALID
              : SubscriptionStepStatus.NONE
          }
        />
        <>
          {steps
            .filter((step) => subscriptionStepTypes.includes(step.type))
            .filter(
              (step) =>
                step.type !== SubscriptionStepType.DISTRIBUTION_IBAN ||
                !(hasAlternativeHoldingMethod && subscription.operation.usePivotFiscalAccount),
            )
            .map((step) => (
              <ProgressStep
                key={step.index}
                index={step.index}
                status={step.status}
                textProps={{ fontSize: 'sm', fontWeight: '500', color: 'gray.500' }}
                title={step.title}
              >
                <>
                  {step.type === SubscriptionStepType.SWAN_ONBOARDING &&
                    step.side === SubscriptionSide.SELLER &&
                    hasAlternativeHoldingMethod && <IdentityVerificationStep isSeller={true} />}
                  {step.type === SubscriptionStepType.IDENTITY_VERIFICATION && (
                    <IdentityVerificationStep isSeller={step.side === SubscriptionSide.SELLER} />
                  )}
                  {step.type === SubscriptionStepType.DOCUMENT_SIGNATURE && (
                    <DocumentSignatureStep />
                  )}
                  {step.type === SubscriptionStepType.PAYMENT && <PaymentStep />}
                </>
              </ProgressStep>
            ))}
        </>
      </Progress>
      {shouldDisplayValidateSubscription && (
        <Button
          isDisabled={isSigningDisabled || validateLoading}
          isLoading={validateLoading}
          size="sm"
          variant="secondary"
          onClick={handleValidateTransaction}
        >
          {isWithdrawalMarket ? t('ValidateAndSignWithdrawal') : t('ValidateAndSignODM')}
        </Button>
      )}
    </Stack>
  );
}

export type TransactionPanelProgressStepsProps = Props;
