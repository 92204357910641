import { Button, Card, Grid, GridItem, HStack, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';
import {
  ArrowNarrowRightIcon,
  ClockIcon,
  CubeTransparentIcon,
  UserIcon,
} from '@heroicons/react/outline';
import {
  InboxInIcon,
  PencilIcon,
  SwitchHorizontalIcon,
  TrendingUpIcon,
} from '@heroicons/react/solid';
import { useMemo } from 'react';
import { useTranslations } from 'use-intl';

import { FundraisingWorkflowType, OperationType } from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { OperationBadge } from '@blockpulse3/ui/commons';

import { OperationRow } from '../../types';

type Props = {
  operation: OperationRow;
  operationSubtitle: string;
  totalAmount?: number;
  isIncomplete?: boolean;
  onClick: () => void;
};

export function OperationCardTemplate({
  operation,
  operationSubtitle,
  totalAmount,
  isIncomplete = false,
  onClick,
}: Props): JSX.Element | null {
  const t = useTranslations();

  const handleRowClick = (): void => {
    onClick();
  };

  const endDate = operation.closingDate || operation.endSubscriptionDate;

  const operationIcon = useMemo(() => {
    switch (operation.type) {
      case OperationType.FUNDRAISING:
        return TrendingUpIcon;
      case OperationType.SECONDARY:
        return SwitchHorizontalIcon;
      case OperationType.OPPORTUNITY:
        return InboxInIcon;
      default:
        return CubeTransparentIcon;
    }
  }, [operation]);

  return (
    <Card padding="4" role="button" onClick={handleRowClick}>
      <Grid
        gap="3"
        gridTemplateColumns="2fr 1fr"
        minHeight="0"
        minWidth="0"
        whiteSpace="nowrap"
        templateAreas={{
          base: `"title title"
      "details button"`,
          md: `"title button"
      "details button"`,
        }}
      >
        <GridItem area="title" minWidth="0">
          <Stack spacing="1">
            <HStack spacing="1">
              <Icon as={operationIcon} boxSize="3" color="primary" />
              <Text color="primary" fontSize="xs" fontWeight="400">
                {operationSubtitle}
              </Text>
            </HStack>
            <HStack>
              <Text fontWeight="600">{operation.name}</Text>
              <OperationBadge
                fontSize="xs"
                operationStatus={operation.status}
                isAllInSubscriptionWorkflow={
                  operation.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN
                }
              />
            </HStack>
          </Stack>
        </GridItem>
        <GridItem alignSelf={{ base: 'end', lg: 'center' }} area="details" minWidth="0">
          <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '2', md: '6' }}>
            <Tooltip
              hasArrow
              fontSize="xs"
              label={<Text textAlign="center">{t('CountOfTransactions')}</Text>}
              placement="top"
            >
              <HStack spacing="1">
                <Icon as={UserIcon} boxSize="3" color="gray.600" m="1" />
                <Text fontSize="sm">{operation.subscriptionCount}</Text>
              </HStack>
            </Tooltip>
            {!!endDate && (
              <HStack spacing="1">
                <Icon as={ClockIcon} boxSize="3" color="gray.600" m="1" />
                <Text fontSize="sm" whiteSpace="nowrap">
                  {formatDate(endDate, 'D MMMM YYYY')}
                </Text>
              </HStack>
            )}
          </Stack>
        </GridItem>
        <GridItem
          alignSelf={{ base: 'end', lg: 'center' }}
          area="button"
          justifySelf={{ base: 'auto', lg: 'end' }}
          minWidth="0"
        >
          <Stack
            alignItems={{ base: 'end', lg: 'center' }}
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: '2', lg: '4' }}
          >
            {totalAmount && <Text fontWeight="600">{formatNumberCurrency(totalAmount)}</Text>}
            {isIncomplete ? (
              <Button
                fontSize="sm"
                rightIcon={<Icon as={PencilIcon} boxSize="4" />}
                variant="secondary"
                onClick={handleRowClick}
              >
                {t('CompleteAction')}
              </Button>
            ) : (
              <Button
                fontSize="sm"
                rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="4" />}
                variant="secondary"
              >
                {t('ShowDetails')}
              </Button>
            )}
          </Stack>
        </GridItem>
      </Grid>
    </Card>
  );
}
