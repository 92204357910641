import { AssetInfosFragment } from '@blockpulse3/graphql/hooks';

import { OperationAssetOption } from './types';

/**
 * getBondTokenDraftsOptions.
 * Returns draft bonds asset options to fill configuration drop down.
 *
 * @param {AssetInfosFragment[]} [bondAssetDrafts]
 * @returns {OperationAssetOption[]}
 */
export function getBondTokenDraftsOptions(
  bondAssetDrafts?: AssetInfosFragment[],
): OperationAssetOption[] {
  if (!bondAssetDrafts) {
    return [];
  }

  return bondAssetDrafts.map((draft) => ({
    label: draft.name,
    type: draft.assetType,
    value: draft.id,
    // data: getBondTokenDraftInfos(draft),
  }));
}
