import { InfoIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  AssetEntityInfosFragment,
  AssetInfosFragment,
  AssetType,
  BondAssetInput,
  useGetAssetDraftsByOperationQuery,
  useGetAssetLazyQuery,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorMessage, Input } from '@blockpulse3/ui/commons';
import {
  AllowedFiscalAdvantageSelect,
  OperationAssetOption,
  OperationAssetSelect,
  defaultFiscalAdvantageOptions,
  getBondTokenDraftsOptions,
} from '@blockpulse3/web-client/operation/commons';
import { BondCreateModal } from '@blockpulse3/web-client/shareholding';

import { getSharePrice, getValuation } from '../../utils';
import { privateFundraisingParametersSchema, privateSubscriptionPeriodOptions } from '../schema';
import { IPrivateFundraisingParametersForm } from '../types';

type Props = {
  /* ** On submit loading ** */
  isLoading?: boolean;
  /* ** Optional loading text to render on the submit button ** */
  loadingText?: string;
  /* ** Default values of the form depending on the page (create & parameters) ** */
  defaultValues?: IPrivateFundraisingParametersForm;
  /* ** Callback on submit form ** */
  onSubmit?: (data: IPrivateFundraisingParametersForm) => void;
};

/**
 * NewPrivateFundraisingInformations.
 * Private fundraising parameters form used in both Create and Update pages.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewPrivateFundraisingInformations({
  isLoading = false,
  loadingText = '',
  defaultValues,
  onSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const bondAssetModal = useDisclosure();

  const [assetDraftInput, setTmpAssetDraftInput] = useState<BondAssetInput | null>(null);
  const [tmpDraftOptions, setTmpDraftOptions] = useState<OperationAssetOption[]>([]);
  const [currentAsset, setCurrentAsset] = useState<
    AssetEntityInfosFragment | BondAssetInput | null
  >(null);

  const navigate = useNavigate();
  const { companyId = '', operationId = '', opportunityId = '' } = useParams();

  const companyReq = useGetCompanyQuery({ variables: { companyId }, skip: !companyId });
  const assetDraftsReq = useGetAssetDraftsByOperationQuery({
    variables: { operationId: opportunityId || operationId },
    skip: !operationId && !opportunityId,
  });
  /* ** Fetch asset on modify click, avoid truth state gap between events ** */
  const [getAsset] = useGetAssetLazyQuery();

  const company = companyReq?.data?.company;
  const draftsBonds = assetDraftsReq.data?.getAssetDraftsByOperation;
  /* ** Previously created bond asset draft ** */
  const draftOptions = getBondTokenDraftsOptions(draftsBonds);

  const { register, control, formState, handleSubmit, reset, setValue, watch } =
    useForm<IPrivateFundraisingParametersForm>({
      defaultValues,
      resolver: yupResolver(privateFundraisingParametersSchema),
    });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  const isRelatedToSecondary = watch('isRelatedToSecondary');
  const asset = watch('asset');

  const { onChange: onValuationChange, ...registerValuation } = register('valuation', {
    valueAsNumber: true,
  });
  const { onChange: onSharePriceChange, ...registerSharePrice } = register('sharePrice', {
    valueAsNumber: true,
  });

  const handleFormSubmit: SubmitHandler<IPrivateFundraisingParametersForm> = (data): void => {
    if (company) {
      onSubmit({ ...data, createBondAsset: assetDraftInput });
    }
  };

  const handleFormCancel = (): void => {
    if (company) {
      navigate(generatePath(routes.company.href, { companyId }));
    }
  };

  const handleValuationChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (company?.actionsOwnedByCompany) {
      const valuation = new BigNumber(e.target.value);
      const sharePrice = getSharePrice(valuation, company.actionsOwnedByCompany);
      setValue('sharePrice', sharePrice);
    }
    onValuationChange(e);
  };

  const handleSharePriceChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (company?.actionsOwnedByCompany) {
      const sharePrice = new BigNumber(e.target.value);
      const valuation = getValuation(sharePrice, company.actionsOwnedByCompany);
      setValue('valuation', valuation);
    }
    onSharePriceChange(e);
  };

  const handleCreateBond = (): void => {
    bondAssetModal.onOpen();
  };

  const handleEditBond = async (option: OperationAssetOption): Promise<void> => {
    if (option.value !== option.type) {
      const assetReq = await getAsset({
        variables: {
          companyId,
          assetId: option.value,
        },
      });
      if (assetReq.data?.asset) {
        setCurrentAsset(assetReq.data?.asset);
      }
    } else if (assetDraftInput) {
      setCurrentAsset(assetDraftInput);
    }
    bondAssetModal.onOpen();
  };

  const handleBondComplete = (asset: AssetInfosFragment | BondAssetInput): void => {
    if ('id' in asset) {
      if (!asset.operation?.id) {
        setTmpDraftOptions([
          {
            label: asset.name,
            value: asset.id,
            type: asset.assetType,
          },
        ]);
      }
      setValue('asset', {
        label: asset.name,
        value: asset.id,
        type: asset.assetType,
      });
      if (operationId || opportunityId) {
        assetDraftsReq.refetch();
      }
    } else {
      setTmpAssetDraftInput(asset);
      setTmpDraftOptions([
        {
          label: asset.name,
          value: asset.assetType,
          type: asset.assetType,
        },
      ]);
      setValue('asset', {
        label: asset.name,
        value: asset.assetType,
        type: asset.assetType,
      });
    }
    bondAssetModal.onClose();
  };

  const handleCloseBondModal = (): void => {
    setCurrentAsset(null);
    bondAssetModal.onClose();
  };

  return (
    <Card variant="divider-top">
      <CardHeader>
        <Heading size="lg">{t('FundraisingSettings')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody>
        <form id="private-fundraising-parameters" onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack spacing="4">
            <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.name}>
                <FormLabel htmlFor="line">{t('CapitalRaiseName')}</FormLabel>
                <Input id="line" type="string" {...register('name')} />
                <ErrorMessage error={formState.errors.name} />
              </FormControl>
            </Stack>
            <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
              <Controller
                control={control}
                name="subscriptionPeriod"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.subscriptionPeriod}>
                    <HStack alignItems="flex-start" spacing="0">
                      <FormLabel htmlFor="city">
                        {t('SubscriptionPeriod')}
                        <Tooltip hasArrow label={t('SubscriptionPeriodInfo')} placement="top">
                          <Icon as={InfoIcon} color="gray.500" ml="2" />
                        </Tooltip>
                      </FormLabel>
                    </HStack>
                    <Select
                      isSearchable={false}
                      menuPlacement="auto"
                      options={privateSubscriptionPeriodOptions}
                      {...field}
                    />
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="asset"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.asset}>
                    <FormLabel htmlFor="asset">{t('AssetType')}</FormLabel>
                    <OperationAssetSelect
                      id="asset"
                      isAddButtonDisabled={draftOptions.length > 0 || tmpDraftOptions.length > 0}
                      menuPlacement="auto"
                      value={field.value}
                      options={[
                        {
                          label: t('OrdinaryShare', { nb: 2 }),
                          type: AssetType.ORDINARY_SHARE,
                          value: AssetType.ORDINARY_SHARE,
                        },
                        ...draftOptions,
                        ...tmpDraftOptions,
                      ]}
                      onAddBondAsset={handleCreateBond}
                      onChange={field.onChange}
                      onEditBondAsset={handleEditBond}
                    />
                    <ErrorMessage error={formState.errors?.asset?.value} />
                  </FormControl>
                )}
              />
            </Stack>
            {asset &&
              [AssetType.ORDINARY_SHARE, AssetType.PREFERRED_SHARE].includes(asset.type) && (
                <>
                  <Stack
                    alignItems="flex-end"
                    direction={{ base: 'column', md: 'row' }}
                    spacing="4"
                  >
                    <FormControl isInvalid={!!formState.errors?.valuation}>
                      <FormLabel htmlFor="line">
                        {t('CompanyValuationBeforeCapitalIncrease')}
                      </FormLabel>
                      <InputGroup>
                        <Input
                          id="valuation"
                          step="0.01"
                          type="number"
                          onChange={handleValuationChange}
                          {...registerValuation}
                        />
                        <InputRightElement color="gray.500">€</InputRightElement>
                      </InputGroup>
                      <ErrorMessage error={formState.errors.valuation} />
                    </FormControl>
                    <FormControl isInvalid={!!formState.errors?.sharePrice}>
                      <FormLabel htmlFor="sharePrice">{t('PricePerShare')}</FormLabel>
                      <InputGroup>
                        <Input
                          id="sharePrice"
                          step="0.01"
                          type="number"
                          onChange={handleSharePriceChange}
                          {...registerSharePrice}
                        />
                        <InputRightElement color="gray.500">€</InputRightElement>
                      </InputGroup>
                      <ErrorMessage error={formState.errors.sharePrice} />
                    </FormControl>
                  </Stack>
                  <Stack
                    alignItems="flex-end"
                    direction={{ base: 'column', md: 'row' }}
                    spacing="4"
                  >
                    <Controller
                      control={control}
                      name="allowedFiscalAdvantages"
                      render={({ field }): JSX.Element => (
                        <FormControl isInvalid={!!formState.errors?.allowedFiscalAdvantages}>
                          <HStack alignItems="flex-start" spacing="0">
                            <FormLabel htmlFor="allowedFiscalAdvantages">
                              {t('EligibleTaxDevicesForOperation')}
                            </FormLabel>
                          </HStack>
                          <AllowedFiscalAdvantageSelect
                            options={defaultFiscalAdvantageOptions}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    />
                  </Stack>
                </>
              )}
            <Accordion
              allowToggle
              defaultIndex={defaultValues?.proofOfFundsThreshold || isRelatedToSecondary ? [0] : []}
              variant="unstyled"
            >
              <AccordionItem>
                <AccordionButton data-cy="expand-parameters">
                  <Text fontWeight="semibold">{t('AdvancedSettings')}</Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel mt="24px" p="0">
                  <Stack spacing="4">
                    <Stack
                      direction={{ base: 'column', md: 'row' }}
                      w={{ base: '100%', md: '48%' }}
                    >
                      <FormControl>
                        <FormLabel htmlFor="proofOfFundsThreshold">
                          {t('ProofOfFundsThreshold')}
                          <Tooltip hasArrow label={t('ProofOfFundsThresholdInfo')} placement="top">
                            <Icon as={InfoIcon} color="gray.600" ml="2" />
                          </Tooltip>
                        </FormLabel>
                        <InputGroup>
                          <Input
                            id="proofOfFundsThreshold"
                            step="0.01"
                            type="number"
                            {...register('proofOfFundsThreshold', { valueAsNumber: true })}
                          />
                          <InputRightElement color="gray.500">€</InputRightElement>
                        </InputGroup>
                        <ErrorMessage error={formState.errors.proofOfFundsThreshold} />
                      </FormControl>
                    </Stack>
                    <Accordion
                      allowToggle
                      defaultIndex={isRelatedToSecondary ? [0] : []}
                      index={isRelatedToSecondary ? [0] : []}
                      variant="unstyled"
                    >
                      <AccordionItem>
                        <HStack>
                          <FormControl>
                            <Checkbox {...register('isRelatedToSecondary')}>
                              <Text fontWeight="semibold">
                                {t('FundraisingRelatedToSecondaryOperation')}
                              </Text>
                            </Checkbox>
                          </FormControl>
                          <AccordionButton maxW="20px">
                            <AccordionIcon />
                          </AccordionButton>
                        </HStack>
                        <AccordionPanel mt="24px" p="0">
                          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                            <FormControl isInvalid={!!formState.errors?.transactionDebitDate}>
                              <FormLabel htmlFor="transactionDebitDate">
                                {t('ExecuteWithdrawalsOnNextDate')}
                              </FormLabel>
                              <Input
                                id="transactionDebitDate"
                                type="date"
                                {...register('transactionDebitDate')}
                              />
                              <ErrorMessage error={formState.errors?.transactionDebitDate} />
                            </FormControl>
                          </Stack>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </form>
        {bondAssetModal.isOpen && (
          <BondCreateModal
            assetType={AssetType.BOND}
            editAsset={currentAsset}
            isNewAsset={true}
            isOpen={bondAssetModal.isOpen}
            onClose={handleCloseBondModal}
            onComplete={handleBondComplete}
          />
        )}
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button type="button" variant="secondary" w="full" onClick={handleFormCancel}>
          {t('BackToDashboard')}
        </Button>
        <Button
          data-cy="next"
          form="private-fundraising-parameters"
          isLoading={isLoading}
          loadingText={loadingText}
          type="submit"
          w="full"
        >
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type NewPrivateFundraisingInformationsProps = Props;
