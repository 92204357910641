import { Button, Icon, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { OperationStatus } from '@blockpulse3/graphql/hooks';
import { CompleteOperationModal } from '@blockpulse3/web-client/operation/commons';

import { useGetOperations } from '../../hook';
import { OperationRow } from '../../types';

type Props = {
  operation: OperationRow;
};

export function OperationTableUnknownRow({ operation }: Props): JSX.Element {
  const t = useTranslations();

  const { operationsRefetch } = useGetOperations(OperationStatus.FINALIZED);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isSpace = useMatch(routes.space.href + '/*');

  const handleOperationCompleted = (): void => {
    operationsRefetch();
    onClose();
  };

  const handleRowClick = (): void => {
    onOpen();
  };

  return (
    <>
      <Tr role="button" onClick={handleRowClick}>
        <Td>
          <Text fontWeight="600">{operation.name}</Text>
          <Text color="gray.500" fontSize="xs" fontWeight="400">
            {t('IncompleteF')}
          </Text>
        </Td>
        {isSpace && (
          <>
            <Td></Td>
            <Td></Td>
            <Td>
              <Text fontWeight="600">{operation.company?.name || '-'}</Text>
            </Td>
          </>
        )}
        <Td isNumeric fontWeight="600">
          -
        </Td>
        <Td>-</Td>
        <Td>-</Td>
        <Td textAlign="right">
          <Button
            fontSize="sm"
            rightIcon={<Icon as={PencilIcon} boxSize="5" />}
            variant="secondary"
          >
            {t('CompleteAction')}
          </Button>
        </Td>
      </Tr>
      {isOpen && (
        <CompleteOperationModal
          isOpen={isOpen}
          operationId={operation.id}
          onClose={onClose}
          onCompleted={handleOperationCompleted}
        />
      )}
    </>
  );
}

export type OperationTableFundraisingRowProps = Props;
