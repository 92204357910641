import {
  Box,
  Progress as ChakraProgress,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { Outlet, generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CompanyStatus,
  CompanyType,
  useDeleteOperationMutation,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import {
  DeleteDraftConfirmModal,
  Progress,
  ProgressStep,
  STEP_HEADER_HEIGHT_BASE,
  STEP_HEADER_HEIGHT_MD,
  StepHeader,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { useOpportunityOperationSteps } from './useOpportunityOperationSteps';

/**
 * NewOpportunityOperationLayout.
 * Layout wrapping opportunity operation workflow.
 *
 * @returns {JSX.Element}
 */
export function NewOpportunityOperationLayout(): JSX.Element {
  const t = useTranslations();

  const top = useBreakpointValue({ base: STEP_HEADER_HEIGHT_BASE, md: STEP_HEADER_HEIGHT_MD });

  const draftConfirmRef = useRef(null);
  const draftConfirmModal = useDisclosure();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { companyId = '', operationId = '' } = useParams();
  const navigate = useNavigate();

  const { data } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = data?.company;

  const isCreateActive = useMatch(routes.company.newOpportunity.href);

  const { activeSteps, progress } = useOpportunityOperationSteps();

  const [deleteOpportunityOperation] = useDeleteOperationMutation();

  const handleCloseNewOpportunityOperation = (): void => {
    if (company?.type === CompanyType.SPV && company.status !== CompanyStatus.RUNNING) {
      navigate(generatePath(routes.onboardings.spv.href, { companyId }));
    } else {
      navigate(generatePath(routes.company.href, { companyId }));
    }
  };

  const handleOpportunityOperationDelete = (): void => {
    deleteOpportunityOperation({
      variables: {
        operationId,
      },
      onCompleted: (res) => {
        if (res.deleteOperation) {
          navigate(generatePath(routes.company.href, { companyId }));
          successToast({ title: t('OperationDeletedSuccessfully') });
        } else {
          errorToast({ title: t('ErrorDeletingOperation') });
        }
      },
      onError: () => {
        errorToast({ title: t('ErrorDeletingOperation') });
      },
    });
  };

  return (
    <Flex direction="column" minH="100vh">
      <StepHeader
        p="4"
        showDelete={!isCreateActive}
        onClose={handleCloseNewOpportunityOperation}
        onDelete={draftConfirmModal.onOpen}
      >
        <Heading size="lg">{t('ConfigureOpportunityOperation')}</Heading>
        <Text color="gray.600" fontWeight="normal">
          {t('EditDocumentInfo')}
        </Text>
      </StepHeader>
      <ChakraProgress
        height={1}
        max={activeSteps.length - 1}
        position="sticky"
        top={top}
        value={progress}
        zIndex={10}
        sx={{
          '&>div': {
            transition: 'width .7s ease',
          },
        }}
      />
      <Box bg="gray.50" flexGrow="1" h="full">
        <Stack direction={{ base: 'column', lg: 'row' }} p="6" spacing={{ base: 4, lg: 0 }}>
          <Flex flexBasis={{ base: 0, lg: 200 }} />
          <Progress>
            {activeSteps.map((step, i) => (
              <ProgressStep
                key={step.label}
                index={i}
                isActive={!!step.value}
                isCompleted={progress > i}
                title={step.label}
              />
            ))}
          </Progress>
          <Flex flexGrow="1" maxW="3xl">
            <Outlet />
          </Flex>
        </Stack>
      </Box>
      <DeleteDraftConfirmModal
        isOpen={draftConfirmModal.isOpen}
        leastDestructiveRef={draftConfirmRef}
        onClose={draftConfirmModal.onClose}
        onDelete={handleOpportunityOperationDelete}
      />
    </Flex>
  );
}
