import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetMovementQuery } from '@blockpulse3/graphql/hooks';

import { OperationPanelHoldingMethod } from '../';

/**
 * MovementPanelHoldingMethod.
 * Display the holding method section of the movement side panel.
 *
 * @returns {JSX.Element}
 */
export function MovementPanelHoldingMethod(): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '', movementId = '' } = useParams();

  const { data, loading, error } = useGetMovementQuery({
    variables: {
      companyId,
      movementId,
    },
    skip: !companyId || !movementId,
  });

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('HoldingMode')}
        </Text>
        <Skeleton h="60px" />
      </Stack>
    );
  }

  const { movement } = data;
  const subscription = movement.subscription;
  const operation = subscription?.operation;

  return subscription && operation ? (
    <OperationPanelHoldingMethod subscriptionId={subscription.id} />
  ) : null;
}
