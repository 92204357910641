import { Card, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { LibraryIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { IntlHoldingMethodTypeShortenedValues } from '@blockpulse3/data/locales/types';
import { HoldingMethod, noop } from '@blockpulse3/data/shared';
import { BalanceInfosFragment } from '@blockpulse3/graphql/hooks';
import { ASSET_COLOR_MAP, CompanyIdentityAvatar } from '@blockpulse3/ui/commons';

import { AssetPerformance } from '../../AssetPerformance';
import { CompanyValuationsChart } from '../../CompanyValuationsChart';

type Props = {
  balance: BalanceInfosFragment;
  onClick?: (balance: BalanceInfosFragment) => void;
  holdingMethods: HoldingMethod[];
};

export function WalletCard({ balance, holdingMethods = [], onClick = noop }: Props): JSX.Element {
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeShortenedValues');

  return (
    <Card
      key={balance.id}
      borderBottomWidth="2px"
      borderColor={ASSET_COLOR_MAP[balance.asset.assetType]}
      borderStyle="solid"
      justifyContent="space-between"
      maxH="200px"
      maxW="150px"
      minH="200px"
      minW="150px"
      overflow="hidden"
      p="3"
      onClick={(): void => onClick(balance)}
    >
      <Stack m="-3" p="3">
        <HStack justify="space-between">
          <CompanyIdentityAvatar src={balance.asset.company.profilePicture} />
          <CompanyValuationsChart
            balance={balance}
            companyId={balance.asset.company.id}
            h="30px"
            lineColor={ASSET_COLOR_MAP[balance.asset.assetType]}
            underlyingAssetType={balance.asset.assetType}
            w="70px"
          />
        </HStack>
        <Stack spacing="0">
          <Text fontWeight="600" noOfLines={2}>
            {balance.asset.company.name}
          </Text>
          <Text color={ASSET_COLOR_MAP[balance.asset.assetType]} fontSize="sm" lineHeight="1.1">
            {balance.asset.name}
          </Text>
        </Stack>
      </Stack>
      <AssetPerformance
        alignItems="flex-start"
        currentPrice={balance.valueOfShare}
        displayPrice={balance.valueOfShares}
        displayTitle={false}
        h="35px"
        initialPrice={balance.subscriptionEntryPrice}
        underlyingAssetType={balance.asset.assetType}
      />
      <HStack overflow="hidden" spacing="1.5">
        <HStack>
          <Icon as={LibraryIcon} boxSize="3" color="gray.500" />
        </HStack>
        {holdingMethods.map((holdingMethod) => (
          <Text key={holdingMethod} fontSize="xs" fontWeight="600" whiteSpace="nowrap">
            {i18nHoldingMethodType(holdingMethod as IntlHoldingMethodTypeShortenedValues)}
          </Text>
        ))}
      </HStack>
    </Card>
  );
}
