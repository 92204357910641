import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  FormControl,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlCSVParseError } from '@blockpulse3/data/locales/types';
import { CsvCellError, CsvError, Exceptions, FormErrors, routes } from '@blockpulse3/data/shared';
import {
  CompanyDocumentType,
  useDeleteCompanyDocumentMutation,
  useGetCompanyDocumentCsvDataQuery,
  useGetCompanyDocumentQuery,
} from '@blockpulse3/graphql/hooks';
import {
  CsvDataTable,
  DownloadCsvTemplate,
  DropzoneInput,
  ErrorMessage,
  ErrorQueryCard,
} from '@blockpulse3/ui/commons';

import { optionGrantsImportSchema } from './schema';
import { RepatriationShareTransferRegisterGrantsForm } from './types';

type Props = unknown;

export function RepatriationShareTransferRegisterGrants(): JSX.Element {
  const t = useTranslations();
  const i18nCSVParseError = useTranslations('CSVParseError');

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [csvGrantsData, setCsvGrantsData] = useState<Record<string, string>[]>([]);
  const [csvGrantsErrors, setCsvGrantsErrors] = useState<CsvCellError[]>([]);

  const { control, formState, setValue, setError, clearErrors, handleSubmit, getValues } =
    useForm<RepatriationShareTransferRegisterGrantsForm>({
      defaultValues: { optionGrantsFile: '' },
      resolver: yupResolver(optionGrantsImportSchema),
    });

  const { companyId = '' } = useParams();
  const navigate = useNavigate();

  const { data: csvGrants, refetch: refetchCsvData } = useGetCompanyDocumentCsvDataQuery({
    variables: {
      companyId,
      documentType: CompanyDocumentType.OPTION_GRANTS_IMPORT,
    },
    skip: !companyId,
    fetchPolicy: 'network-only',
  });

  const { data, loading, error, refetch } = useGetCompanyDocumentQuery({
    variables: {
      companyId,
      documentType: CompanyDocumentType.OPTION_GRANTS_IMPORT,
    },
    onCompleted: () => {
      setValue('optionGrantsFile', data?.getCompanyDocument?.document.title);
    },
  });
  const [deleteCompanyDocument] = useDeleteCompanyDocumentMutation();

  useEffect(() => {
    if (getValues('optionGrantsFile')) {
      setCsvGrantsData(csvGrants?.getCompanyDocumentCsvData || []);
    }
  }, [csvGrants, getValues]);

  if (loading) {
    return (
      <Skeleton width="full">
        <Card height="400px" width="full" />
      </Skeleton>
    );
  }

  if (!data || error) {
    return <ErrorQueryCard height="400px" width="full" />;
  }

  const document = data.getCompanyDocument?.document;
  const fileName = document ? { [document.id]: new File([document.title], document.title) } : {};

  const handleFileDrop = async (acceptedFiles: File[]): Promise<void> => {
    clearErrors('optionGrantsFile');

    const formData = new FormData();
    formData.append('optionGrants', acceptedFiles[0]);
    formData.append('companyId', companyId);

    setIsUploading(true);
    setCsvGrantsData([]);
    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/companies/uploadOptionGrantsDocument',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        setIsUploading(false);
        setValue('optionGrantsFile', 'file.csv');
        setCsvGrantsErrors([]);
        refetch();
        refetchCsvData();
      })
      .catch((err: unknown) => {
        if (axios.isAxiosError(err)) {
          const error = err?.response?.data as CsvError;
          const errorMessage = error?.message ?? error.message;
          const errorCsvData = error?.data || [];
          const errorCsvCells = error?.cellErrors || [];

          setCsvGrantsData(errorCsvData);
          setCsvGrantsErrors(errorCsvCells);

          if (!error.code && errorMessage === Exceptions.MissingRequiredProperties) {
            setError('optionGrantsFile', {
              type: 'custom',
              message: t('SomeRequiredInfoMissing'),
            });
          } else {
            const code = error.code || 'DefaultError';
            setError('optionGrantsFile', {
              type: 'custom',
              message: i18nCSVParseError(code as IntlCSVParseError),
            });
          }
        } else {
          setError('optionGrantsFile', {
            type: 'custom',
            message: FormErrors.DropzoneInvalidTemplate,
          });
        }
        setIsUploading(false);
      });
  };

  const handleFileDelete = (): void => {
    deleteCompanyDocument({
      variables: {
        deleteCompanyDocumentInput: {
          companyId,
          documentType: CompanyDocumentType.OPTION_GRANTS_IMPORT,
        },
      },
      onCompleted: () => {
        setValue('optionGrantsFile', undefined);
        setCsvGrantsData([]);
        refetchCsvData();
        refetch();
      },
    });
  };

  const handleStepCancel = (): void => {
    navigate('../' + routes.company.repatriation.import.href);
  };

  const handleFormSubmit: SubmitHandler<RepatriationShareTransferRegisterGrantsForm> = (
    data,
  ): void => {
    navigate('../' + routes.company.repatriation.summary.href);
  };

  return (
    <Card variant="divider-top" width="full">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{t('OptionGrantsImport')}</Heading>
        <Text color="gray.600" fontSize="lg">
          {t('OptionGrantsImportDescription')}
        </Text>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        <Alert status="info">
          <AlertIcon />
          <AlertTitle>
            <DownloadCsvTemplate path={'/assets/option_grants_template.csv'} />
          </AlertTitle>
        </Alert>
        <form id="upload-optiongrants-file" onSubmit={handleSubmit(handleFormSubmit)}>
          <Controller
            control={control}
            name="optionGrantsFile"
            render={(): JSX.Element => (
              <FormControl>
                <DropzoneInput
                  accept={{ 'text/csv': [] }}
                  files={fileName}
                  isLoading={isUploading}
                  maxFiles={1}
                  subTitle=".CSV"
                  icon={
                    <Icon as={DocumentTextIcon} boxSize="42px" color="gray.400" strokeWidth="1px" />
                  }
                  onDelete={handleFileDelete}
                  onDrop={handleFileDrop}
                />
                <ErrorMessage error={formState.errors?.optionGrantsFile} />
              </FormControl>
            )}
          />
        </form>
        <CsvDataTable cellErrors={csvGrantsErrors} csvData={csvGrantsData} />
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button variant="secondary" w="full" onClick={handleStepCancel}>
          {t('Back')}
        </Button>
        <Button form="upload-optiongrants-file" type="submit" w="full">
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type RepatriationShareTransferRegisterGrantsProps = Props;
