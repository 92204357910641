import {
  Button,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { CubeTransparentIcon, PlusIcon } from '@heroicons/react/outline';
import { generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import { CompanyStatus, CompanyType, useGetCompanyQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { OperationTypeModal } from '@blockpulse3/web-client/operation/commons';

import { OperationHeadlessCompanyModal } from './OperationCompanyHeadlessModal';
import { useGetOperationsCount } from './OperationRows/hook';
import { OperationRowDisplayType } from './OperationRows/types';
import { OperationRowsFull } from './OperationRowsFull';
import { OperationRowsPreview } from './OperationRowsPreview';

type Props = {
  displayType: OperationRowDisplayType;
  limit?: number;
};

/**
 * OperationList.
 * Component displaying operation cards.
 *
 * @returns {JSX.Element}
 */
export function OperationList({ displayType, limit }: Props): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const navigate = useNavigate();

  const isSpace = useMatch(routes.space.href + '/*');

  const operationTypeModal = useDisclosure();
  const operationTypeHeadlessModal = useDisclosure();

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const isFeatureOperationHeadless = isFeatureEnabled('fundraisingHeadless');

  const { operationsCount, operationsCountLoading, operationsCountError } = useGetOperationsCount();

  if (operationsCountLoading) {
    return (
      <Stack spacing="4">
        {!isSpace && (
          <Heading size="md">{t(limit ? 'MyCurrentOperations' : 'MyOperations')}</Heading>
        )}
        <Stack gap="3">
          <Skeleton minH="111px"></Skeleton>
          <Skeleton minH="111px"></Skeleton>
          <Skeleton minH="111px"></Skeleton>
        </Stack>
      </Stack>
    );
  }

  if (operationsCountError) {
    return (
      <Stack spacing="4">
        {!isSpace && (
          <Heading size="md">{t(limit ? 'MyCurrentOperations' : 'MyOperations')}</Heading>
        )}
        <ErrorQueryCard h="200px" />
      </Stack>
    );
  }

  const isRestrictedToOpportunities =
    !company?.type ||
    !(
      (!isFeatureOperationHeadless &&
        [CompanyType.SPV, CompanyType.TOKENIZED].includes(company?.type)) ||
      (isFeatureOperationHeadless &&
        [CompanyType.SPV, CompanyType.PARTIAL, CompanyType.TOKENIZED].includes(company?.type))
    ) ||
    (company?.type === CompanyType.SPV && company?.status !== CompanyStatus.RUNNING);

  const { started = 0, passed = 0, drafts = 0 } = operationsCount;
  const computedOperationsCount = started + passed + drafts;
  const hasOperations = computedOperationsCount > 0;

  const handleNewOperationClick = (): void => {
    if (!companyId) return;

    if (company?.type === CompanyType.PARTIAL) {
      if (isFeatureOperationHeadless) {
        return operationTypeHeadlessModal.onOpen();
      }
      return;
    }

    return openOperationTypeModal();
  };

  const handleNewOpportunity = (): void => {
    if (!companyId) return;

    navigate(generatePath(routes.company.newOpportunity.full, { companyId }));
  };

  const openOperationTypeModal = (): void => {
    if (operationTypeHeadlessModal.isOpen) {
      operationTypeHeadlessModal.onClose();
    }

    return operationTypeModal.onOpen();
  };

  return (
    <Stack spacing="4">
      {!isSpace && (
        <>
          {operationTypeModal.isOpen && (
            <OperationTypeModal
              isOpen={operationTypeModal.isOpen}
              onClose={operationTypeModal.onClose}
            />
          )}
          {operationTypeHeadlessModal.isOpen && (
            <OperationHeadlessCompanyModal
              isOpen={operationTypeHeadlessModal.isOpen}
              onClose={operationTypeHeadlessModal.onClose}
              onSubmit={openOperationTypeModal}
            />
          )}
        </>
      )}
      {!isSpace && (
        <Stack
          alignItems={{ base: 'flex-start', md: 'center' }}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          <Heading size="md">{t(limit ? 'MyCurrentOperations' : 'MyOperations')}</Heading>
          {hasOperations &&
            (isRestrictedToOpportunities ? (
              <Tooltip
                isDisabled={computedOperationsCount === 0}
                label={
                  company?.type === CompanyType.SPV
                    ? t('AddUniqueOpportunitySPVTooltip')
                    : t('AddUniqueOpportunityCompanyTooltip')
                }
              >
                <Button
                  data-cy="new-opportunity"
                  isDisabled={!company || computedOperationsCount > 0}
                  leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                  size="sm"
                  onClick={handleNewOpportunity}
                >
                  {t('CreateOpportunity')}
                </Button>
              </Tooltip>
            ) : (
              <Button
                data-cy="new-fundraising"
                isDisabled={!company || isRestrictedToOpportunities}
                leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                size="sm"
                onClick={handleNewOperationClick}
              >
                {t('CreateNewOperation')}
              </Button>
            ))}
        </Stack>
      )}
      {hasOperations &&
        (limit ? (
          <OperationRowsPreview displayType={displayType} limit={limit} />
        ) : (
          <OperationRowsFull displayType={displayType} />
        ))}
      {!hasOperations && (
        <Stack layerStyle="emptyState">
          <Icon as={CubeTransparentIcon} boxSize="40px" color="gray.500" />
          <Text>
            {isRestrictedToOpportunities && !isSpace
              ? t('StartGatheringIntentsNow')
              : t('NoOperationsYet')}
          </Text>
          {!isSpace && (
            <Stack>
              {isRestrictedToOpportunities ? (
                <Button
                  data-cy="new-opportunity"
                  isDisabled={!company || computedOperationsCount > 0}
                  leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                  size="sm"
                  onClick={handleNewOpportunity}
                >
                  {t('CreateOpportunity')}
                </Button>
              ) : (
                <Button
                  data-cy="new-fundraising"
                  isDisabled={!company || isRestrictedToOpportunities}
                  leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                  size="sm"
                  onClick={handleNewOperationClick}
                >
                  {t('CreateNewOperation')}
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}
