import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Progress,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FundraisingWorkflowType, useGetOperationQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberPercentage } from '@blockpulse3/helpers';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

type Props = unknown;

/**
 * FundraisingInformations.
 * Information section, such as percentage, amount... of the Fundraising.
 *
 * @returns {JSX.Element}
 */
export function FundraisingInformations(): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const { data, loading, error } = useGetOperationQuery({
    variables: {
      operationId,
    },
  });

  if (loading || !operationId) {
    return (
      <Skeleton>
        <Card h="195px" />
      </Skeleton>
    );
  }

  if (error || !data?.operation.fundraising) {
    return <ErrorQueryCard h="195px" />;
  }

  const { fundraising, ...operation } = data.operation;

  if (operation.data?.__typename !== 'FundraisingData') {
    return <ErrorQueryCard h="195px" />;
  }

  const isAllInSubscriptionWorkflow = fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;

  const objective = operation?.data?.objective || 0;
  const totalApprovedAmount = operation?.data?.totalApprovedAmount || 0;
  const totalDeclaredAmount = operation?.data?.totalDeclaredAmount || 0;
  const committedAmount = operation?.data?.committedAmount || 0;

  return (
    <Card>
      <CardHeader>
        <Heading size="md">{t('GlobalInformation')}</Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing="4">
          <Progress colorScheme="green" rounded="md" value={objective} />
          <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('OngoingSubscriptionAmounts')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberCurrency(committedAmount)}
              </Text>
              {isAllInSubscriptionWorkflow && totalDeclaredAmount && (
                <Text color="gray.500" fontSize="sm">
                  <Text as="span" fontWeight="500">
                    {t('Provided')}
                  </Text>{' '}
                  {formatNumberCurrency(totalDeclaredAmount)}
                </Text>
              )}
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('GoalAchievedAt')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberPercentage(objective)}
              </Text>
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {isAllInSubscriptionWorkflow ? t('AmountValidated') : t('CollectedAmount')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberCurrency(totalApprovedAmount)}
              </Text>
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('InvitedSubscriber', { nb: operation.subscriptionCount })}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {operation.subscriptionCount}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
}

export type FundraisingInformationsProps = Props;
