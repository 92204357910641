import { Circle, Stack, Text, TextProps, VStack } from '@chakra-ui/react';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { DotsHorizontalIcon } from '@heroicons/react/solid';

import { SubscriptionStepStatus, noop } from '@blockpulse3/data/shared';

export type StepState = {
  isActive: boolean;
  isCompleted: boolean;
};

type Props = {
  /* ** Title of the step ** */
  title?: string;
  /* ** Subtitle of the step ** */
  subtitle?: string;
  /* ** Index of the step ** */
  index: number;
  /* ** Progress step status ** */
  status?: SubscriptionStepStatus;
  /* ** Is the step active ** */
  isActive?: boolean;
  /* ** Is the step completed ** */
  isCompleted?: boolean;
  /* ** Text Props ** */
  textProps?: TextProps;
  /* ** On click, (go to) ** */
  onClick?: (index: number, { isActive, isCompleted }: StepState) => void;
  /* ** extra button ** */
  children?: JSX.Element | null;
};

/**
 * ProgressStep.
 *
 * Title step used in <Progress />. Render a different
 * icon for each status. The layout scrolls on mobile
 * devices.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function ProgressStep({
  index,
  title,
  subtitle,
  status,
  isActive = false,
  isCompleted = false,
  textProps = {},
  onClick = noop,
  children,
}: Props): React.ReactElement<ProgressStepProps> {
  const handleStepClick = (): void => {
    onClick(index, { isActive, isCompleted });
  };

  const getStepColor = (): string => {
    if (isCompleted) {
      return 'secondary';
    }
    switch (status) {
      case SubscriptionStepStatus.VALID:
        return 'green.400';
      case SubscriptionStepStatus.ERROR:
        return 'red.400';
      case SubscriptionStepStatus.PENDING:
        return 'orange.400';
      case SubscriptionStepStatus.IN_PROGRESS:
        return 'yellow.300';
    }
    if (isActive) {
      return 'accent';
    }
    return 'gray.200';
  };

  const RenderStepIcon = (): JSX.Element => {
    if (status === SubscriptionStepStatus.ERROR) {
      return <XIcon color="white" height="12" width="12" />;
    }
    if (
      (status && [SubscriptionStepStatus.VALID, SubscriptionStepStatus.PENDING].includes(status)) ||
      isCompleted
    ) {
      return <CheckIcon color="white" height="12" width="12" />;
    }
    if (status && status === SubscriptionStepStatus.IN_PROGRESS) {
      return <DotsHorizontalIcon color="white" height="12" width="12" />;
    }
    if (isActive) {
      return <Circle bg="secondary" size="2" />;
    }
    return <Circle bg="gray.200" size="2" />;
  };

  const color = getStepColor();

  return (
    <Stack
      align="center"
      alignItems="baseline"
      direction="row"
      spacing="4"
      onClick={handleStepClick}
    >
      <Circle bg={color} borderColor={color} borderWidth={isCompleted ? '0' : '2px'} size="4">
        <RenderStepIcon />
      </Circle>
      {title && (
        <VStack align="start" spacing="1">
          <Text
            color="emphasized"
            fontWeight="medium"
            whiteSpace={{ base: 'nowrap', lg: 'unset' }}
            {...textProps}
          >
            {title}
          </Text>
          {subtitle && (
            <Text color="gray.800" fontSize="xs" fontWeight="600">
              {subtitle}
            </Text>
          )}
          {children}
        </VStack>
      )}
    </Stack>
  );
}

export type ProgressStepProps = Props;
