import { OperationCSVLabel, OperationCSVType } from './types';

export const operationCsvLabels: Record<OperationCSVType, OperationCSVLabel> = {
  [OperationCSVType.FUNDRAISING_PRIVATE]: {
    csvTemplateLink: 'assets/blockpulse_template_equity.csv',
    successToastTitle: 'ImportedSubscribersSuccessfully',
    alertTitle: 'ImportedSubscribersReplaceWarning',
    submitTitle: 'ImportSubscribersAction',
  },
  [OperationCSVType.FUNDRAISING_CROWDFUNDING]: {
    csvTemplateLink: 'assets/blockpulse_template_equity_crowdfunding.csv',
    successToastTitle: 'ImportedSubscribersSuccessfully',
    alertTitle: 'ImportedSubscribersReplaceWarning',
    submitTitle: 'ImportSubscribersAction',
  },
  [OperationCSVType.SECONDARY_BUYER]: {
    csvTemplateLink: 'assets/blockpulse_template_secondary_buyer.csv',
    successToastTitle: 'ImportedBuyersSuccessfully',
    alertTitle: 'ImportedBuyersAmountWarning',
    submitTitle: 'ImportBuyersAction',
  },
  [OperationCSVType.SECONDARY_SELLER]: {
    csvTemplateLink: 'assets/blockpulse_template_secondary_seller.csv',
    successToastTitle: 'ImportedSellersSuccessfully',
    alertTitle: 'ImportedSellersReplaceWarning',
    submitTitle: 'ImportSellersAction',
  },
};
