import * as yup from 'yup';

import {
  numberField,
  requiredBooleanField,
  requiredNumberField,
  requiredPastDateField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { AssetType, TokenDurationUnit } from '@blockpulse3/graphql/hooks';

import { IBondAsset } from './types';

export const bondAssetTypeOptions = [{ label: AssetType.BOND, value: AssetType.BOND }];

export const durationUnitOptions = [
  { label: TokenDurationUnit.day, value: TokenDurationUnit.day },
  { label: TokenDurationUnit.month, value: TokenDurationUnit.month },
];

export const schema = (isNewAsset: boolean): yup.ObjectSchema<IBondAsset> =>
  yup.object({
    name: requiredStringField,
    nominalValue: requiredNumberField,
    durationUnit: yup.object({
      label: requiredStringField,
      value: requiredStringField,
    }),
    period: requiredNumberField,
    reimbursementInterval: numberField,
    interestRate: requiredNumberField,
    interestPeriod: requiredNumberField,
    interestPaymentInterval: numberField,
    issuancePremium: numberField,
    redemptionPremium: numberField,
    accelerable: requiredBooleanField,
    extendable: requiredBooleanField,
    transferable: requiredBooleanField,
    ...(!isNewAsset ? { date: requiredPastDateField } : {}),
  }) as yup.ObjectSchema<IBondAsset>;

export const defaultValues: IBondAsset = {
  name: '',
  nominalValue: 0,
  date: '',
  durationUnit: durationUnitOptions[0],
  period: 0,
  reimbursementInterval: 0,
  interestRate: 0,
  interestPeriod: 0,
  interestPaymentInterval: 0,
  issuancePremium: 0,
  redemptionPremium: 0,
  accelerable: false,
  extendable: false,
  transferable: false,
};
