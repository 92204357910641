import {
  Button,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon, XCircleIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { SubscriptionTab, SubscriptionsTabsStatus } from '@blockpulse3/data/shared';
import {
  SubscriptionsFilterInput,
  useGetTotalFilteredSubscriptionsCountAndAmountQuery,
} from '@blockpulse3/graphql/hooks';
import { downloadFile, formatNumberCurrency } from '@blockpulse3/helpers';
import { Input, useErrorToast } from '@blockpulse3/ui/commons';

import { SecondaryOperationTransactionTableFilters } from './SecondaryOperationTransactionTableFilters';

type Props = {
  /* ** List of tab names and other properties ** */
  tabs: SubscriptionTab[];
  /* ** Controlled active tab index ** */
  activeTabIndex: number;
  /* ** Active filters ** */
  filters: SubscriptionsFilterInput[];
  /* ** Callback on filter update ** */
  onFiltersChange: (value: SubscriptionsFilterInput[]) => void;
  /* ** Callback on search submit ** */
  onSearchInputSubmit: (value: string) => void;
};

export function SecondaryOperationTransactionControls({
  filters,
  tabs,
  activeTabIndex,
  onFiltersChange,
  onSearchInputSubmit,
}: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const errorToast = useErrorToast();

  const [searchInput, setSearchInput] = useState<string>('');
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  const { data: countData, loading: countLoading } =
    useGetTotalFilteredSubscriptionsCountAndAmountQuery({
      variables: {
        operationId,
        filterBy: filters,
      },
      skip: !filters.length,
    });

  /* ** Filter modal button condition ** */
  const showFilters = [
    SubscriptionsTabsStatus.ALL_STATUS,
    SubscriptionsTabsStatus.PENDING,
  ].includes(tabs[activeTabIndex].name);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
    onSearchInputSubmit('');
  };

  const handleSearchInputSubmit = (): void => {
    onSearchInputSubmit(searchInput);
  };

  const handleFiltersChange = (value: SubscriptionsFilterInput[]): void => {
    onFiltersChange(value);
  };

  const handleSubscribersExport = async (): Promise<void> => {
    setIsExportLoading(true);
    try {
      await axios
        .post(
          `${process.env['NX_API_CONTROLLER_ENDPOINT']}/operations/exportSubscriptions`,
          { operationId, filterBy: filters },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            responseType: 'blob',
          },
        )
        .then((response) => {
          downloadFile(response.data, 'export-transactions.csv');
        });
    } catch (err) {
      errorToast({ title: t('ExportCSVError') });
    } finally {
      setIsExportLoading(false);
    }
  };

  const totalTransaction: number =
    countData?.getTotalFilteredSubscriptionsCountAndAmount.totalSubscription || 0;

  const totalTradedAmount: number =
    countData?.getTotalFilteredSubscriptionsCountAndAmount.totalInvestAmount || 0;

  return (
    <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
      <Stack direction={{ base: 'column', md: 'row' }}>
        <InputGroup width={{ base: 'full', md: '300px' }}>
          <Input
            paddingRight="80px"
            placeholder={t('Search')}
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={(e): void => {
              if (e.key === 'Enter') {
                handleSearchInputSubmit();
              }
            }}
          />
          <InputRightElement
            flexDirection="row-reverse"
            justifyContent="space-between"
            paddingX="1"
            width="80px"
          >
            <IconButton
              aria-label="search-database"
              icon={<Icon as={SearchIcon} />}
              size="sm"
              variant="secondary"
              onClick={handleSearchInputSubmit}
            />
            {!!searchInput && (
              <IconButton
                aria-label="reset"
                icon={<Icon as={XCircleIcon} />}
                size="sm"
                variant="ghost"
                onClick={handleSearchInputReset}
              />
            )}
          </InputRightElement>
        </InputGroup>
        {showFilters && (
          <SecondaryOperationTransactionTableFilters
            defaultValue={filters}
            onSubmit={handleFiltersChange}
          />
        )}
        {filters.length > 0 && !countLoading && (
          <HStack>
            <Text
              color="gray.800"
              fontSize="sm"
              px={{ base: '0', md: '3' }}
              rounded="md"
              textAlign="center"
            >
              {t.rich('TransactionCount', {
                nb: totalTransaction,
                important: (chunk) => (
                  <Text as="span" fontWeight="bold">
                    {chunk}
                  </Text>
                ),
              })}{' '}
              -{' '}
              {t.rich('AmountTraded', {
                nb: totalTradedAmount,
                nbFormatted: formatNumberCurrency(totalTradedAmount),
                important: (chunk) => (
                  <Text as="span" fontWeight="bold">
                    {chunk}
                  </Text>
                ),
              })}
            </Text>
          </HStack>
        )}
      </Stack>
      <HStack alignItems="flex-start">
        <Button
          isDisabled={!tabs[activeTabIndex].count}
          isLoading={isExportLoading}
          loadingText={t('FileGeneration')}
          rightIcon={<Icon as={DownloadIcon} />}
          variant="secondary"
          onClick={handleSubscribersExport}
        >
          {t('ExportAction')}
        </Button>
      </HStack>
    </Stack>
  );
}

export type SecondaryOperationTransactionControlsProps = Props;
