import {
  Card,
  HStack,
  Icon,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, TemplateIcon } from '@heroicons/react/outline';
import {
  SortingState,
  Updater,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { MovementInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import {
  AssetBadge,
  IdentityAvatar,
  PaginationButtons,
  TableContainer,
} from '@blockpulse3/ui/commons';
import { PaginationHandlers, useDisclosureLink } from '@blockpulse3/ui/ui-hooks';
import { MovementPanel } from '@blockpulse3/web-client/operation/commons';

type Props = PaginationHandlers<MovementInfosFragment> & {
  isPreview?: boolean;
  onRowClick?: (id: string) => void;
  sorting?: SortingState;
  onSortingChange: (sortFn: Updater<SortingState>) => void;
};

/**
 * ShareTransferRegisterTable.
 * RMT table with paginations.
 *
 * @returns {JSX.Element}
 */

export function ShareTransferRegisterPaginatedTable({
  isPreview = false,
  results,
  pageInfo,
  pageCount,
  currentPage,
  isLoadingMore,
  loading,
  handleNextPage,
  handlePreviousPage,
  onSortingChange,
  sorting,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nMovementType = useTranslations('MovementTypeValues');

  const rmtRoutes = routes.company.captable.rmt;

  const { isOpen, onOpen, onClose } = useDisclosureLink({
    hiddenHref: rmtRoutes.href,
    visibleHref: rmtRoutes.movement.href,
  });

  const columnHelper = useMemo(() => createColumnHelper<MovementInfosFragment>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('number', {
        header: t('Number', { nb: 1 }),
        enableSorting: true,
        cell: (info) => <Td fontWeight="600">{info.getValue()}</Td>,
      }),
      columnHelper.accessor('fromWallet', {
        header: t('Holder', { nb: 1 }),
        enableSorting: false,
        cell: ({ row }) => {
          const holder = row.original.fromWallet;
          const fromWalletIndex = row.original.fromWalletIndex;
          return (
            <Td>
              {holder?.identity ? (
                <HStack spacing="2.5">
                  <IdentityAvatar identity={holder?.identity} />
                  <Stack spacing="1">
                    <Text fontWeight="600">{holder?.identity?.name}</Text>
                    {fromWalletIndex && (
                      <Text color="gray.500" fontWeight="400">
                        {fromWalletIndex}
                      </Text>
                    )}
                  </Stack>
                </HStack>
              ) : (
                <Text fontWeight="600" textAlign="left">
                  -
                </Text>
              )}
            </Td>
          );
        },
      }),
      columnHelper.accessor('toWallet', {
        header: t('Recipient', { nb: 1 }),
        enableSorting: false,
        cell: ({ row }) => {
          const recipient = row.original.toWallet;
          const toWalletIndex = row.original.toWalletIndex;
          return (
            <Td>
              {recipient?.identity ? (
                <HStack spacing="2.5">
                  <IdentityAvatar identity={recipient.identity} />
                  <Stack spacing="1">
                    <Text fontWeight="600">{recipient?.identity?.name}</Text>
                    {toWalletIndex && (
                      <Text color="gray.500" fontWeight="400">
                        {toWalletIndex}
                      </Text>
                    )}
                  </Stack>
                </HStack>
              ) : (
                <Text fontWeight="600" textAlign="left">
                  -
                </Text>
              )}
            </Td>
          );
        },
      }),
      columnHelper.accessor('asset', {
        header: t('CountOfAssets'),
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Td fontWeight="600">
              <AssetBadge type={row.original.asset.assetType} value={row.original.amount} />
            </Td>
          );
        },
      }),
      columnHelper.accessor('createdAt', {
        header: t('MovementDate', { nb: 1 }),
        enableSorting: false,
        cell: (info) => {
          const createdAt = info.getValue();
          return (
            <Td textAlign="right">
              <Text fontWeight="600">{formatDate(createdAt, 'D MMMM YYYY')}</Text>
            </Td>
          );
        },
      }),
      columnHelper.accessor('detailedType', {
        header: t('NatureOfMovement', { nb: 1 }),
        enableSorting: false,
        cell: (info) => {
          const detailedType = info.getValue();
          return (
            <Td color="secondary" fontWeight="600" textAlign="right">
              <Text fontWeight="600">{detailedType ? i18nMovementType(detailedType) : ''}</Text>
            </Td>
          );
        },
      }),
    ],
    [t, columnHelper],
  );

  const table = useReactTable({
    data: results,
    columns,
    state: { sorting },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (sortFn) => {
      onSortingChange(sortFn);
    },
    enableSortingRemoval: false,
  });

  const handleRowClick = (movementId: string): void => {
    if (isPreview) return;
    onOpen(generatePath(rmtRoutes.movement.href, { movementId }));
  };

  if (loading) {
    return (
      <Skeleton>
        <Card h="400px" />
      </Skeleton>
    );
  }

  if (results.length === 0 && !isLoadingMore) {
    return (
      <Stack layerStyle="emptyState">
        <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
        <Text>{t('NoResult')}</Text>
      </Stack>
    );
  }

  const numericColumns = ['amount'];
  return (
    <Stack spacing="4">
      {!isPreview && isOpen && <MovementPanel isOpen={isOpen} onClose={onClose} />}
      <TableContainer maxHeight="none">
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    cursor={header.column.getCanSort() ? 'pointer' : 'initial'}
                    isNumeric={numericColumns.includes(header.id)}
                    w={header.id === 'identity' ? '40%' : '15%'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <HStack spacing="0">
                      <Text>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </Text>
                      {header.column.getIsSorted() && (
                        <Icon
                          boxSize="16px"
                          as={
                            header.column.getIsSorted() === 'asc' ? ChevronUpIcon : ChevronDownIcon
                          }
                        />
                      )}
                    </HStack>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoadingMore ? (
              <Tr key="0">
                <Td colSpan={6}>
                  <Stack alignItems="center" h="738px" justifyContent="center">
                    <Spinner />
                  </Stack>
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.original.id}
                  role="button"
                  onClick={(): void => handleRowClick(row.original.id)}
                >
                  {row.getVisibleCells().map((cell) =>
                    flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      key: cell.id,
                    }),
                  )}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationButtons
        currentPage={currentPage}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        loading={isLoadingMore}
        pageCount={pageCount}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
      />
    </Stack>
  );
}
