import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Divider,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useTranslations } from 'use-intl';

import { AssetType } from '@blockpulse3/data/shared';
import {
  MovementType,
  MovementWithSubscriptionInfosFragment,
  OptionTokenGrantFragment,
  OptionTokenInfosFragment,
} from '@blockpulse3/graphql/hooks';
import { formatNumberInt } from '@blockpulse3/helpers';
import { AssetBadge } from '@blockpulse3/ui/commons';
import { SubscriptionPanelDocuments } from '@blockpulse3/web-client/operation/commons';

import { getOptionTokenGrantStatus, useGrantBadge } from '../../../../utils';

type Props = {
  movement: MovementWithSubscriptionInfosFragment;
  grants: OptionTokenGrantFragment[];
  optionToken: OptionTokenInfosFragment;
};

export function OptionTransactionListItem({
  movement,
  grants,
  optionToken,
}: Props): JSX.Element | null {
  const t = useTranslations();

  const { getBadge } = useGrantBadge();

  if (movement.type === MovementType.BURN) return null;

  const grant = grants.find(
    (g) =>
      dayjs.unix(g.date).format('YYYYMMDD') === dayjs(movement.createdAt).format('YYYYMMDD') ||
      parseInt(dayjs.unix(g.date).format('YYYYMMDD')) ===
        parseInt(dayjs(movement.createdAt).format('YYYYMMDD')) + 1,
  );
  const badge = getBadge(getOptionTokenGrantStatus(optionToken, grant));

  return (
    <Accordion allowToggle variant="unstyled">
      <AccordionItem>
        <AccordionButton _expanded={{ fontWeight: '600' }}>
          <HStack justifyContent="space-between" spacing="1" w="full">
            <HStack spacing="1">
              <AccordionIcon ml="-1" />
              <Text fontSize="sm">
                {t('AssignedDate', {
                  date: dayjs(movement.createdAt).format('D MMMM YYYY'),
                })}
              </Text>
            </HStack>
            <Badge colorScheme={badge.color} fontSize="xs">
              {badge.label}
            </Badge>
          </HStack>
        </AccordionButton>
        <AccordionPanel as={Stack} py="2" spacing="4">
          <Stack p="2" spacing="2">
            <HStack fontSize="sm" justifyContent="space-between">
              <Text color="gray.500" fontWeight="500">
                {t('CountOfAssets')}
              </Text>
              <Stack alignItems="flex-end" flexShrink="0" spacing="0">
                <AssetBadge type={movement.asset.assetType} value={movement.amount} />
              </Stack>
            </HStack>
            <HStack fontSize="sm" justifyContent="space-between">
              <Text color="gray.500" fontWeight="500">
                {t('VestedQuantity')}
              </Text>
              <Text fontWeight="500">
                {grant?.vestedAmount ? formatNumberInt(grant.vestedAmount || 0) : '-'}
              </Text>
            </HStack>
            {movement.asset.assetType !== AssetType.AGA && (
              <HStack fontSize="sm" justifyContent="space-between">
                <Text color="gray.500" fontWeight="500">
                  {t('ExercisedQuantity')}
                </Text>
                <Text fontWeight="500">
                  {grant?.exercisedAmount ? formatNumberInt(grant.exercisedAmount) : '-'}
                </Text>
              </HStack>
            )}
            {movement.subscription && (
              <SubscriptionPanelDocuments
                p="0"
                subscriptionId={movement.subscription.id}
                titleFontSize="sm"
              />
            )}
          </Stack>
          <Divider />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
