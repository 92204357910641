import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ArchiveIcon,
  ArrowNarrowRightIcon,
  ClockIcon,
  ShieldCheckIcon,
  TableIcon,
  UploadIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { useState } from 'react';
import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  FundraisingType,
  FundraisingWorkflowType,
  Operation,
  OperationStatus,
} from '@blockpulse3/graphql/hooks';
import { downloadCsv, downloadFile, formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { OperationBadge, useErrorToast } from '@blockpulse3/ui/commons';

import { ExtendOperationModal } from '../../../ExtendOperationModal';
import { UploadSubscriptionTemplateModal } from '../../../UploadSubscriptionTemplateModal';
import { OperationRow } from '../../types';

type Props = {
  operation: OperationRow;
};

export function OperationTableFundraisingRow({ operation }: Props): JSX.Element {
  const t = useTranslations();

  const [isPECLoading, setIsPECLoading] = useState<boolean>(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState<boolean>(false);
  const [isSignedCertificatesLoading, setIsSignedCertificatesLoading] = useState<boolean>(false);

  const { companyId = '' } = useParams();

  const errorToast = useErrorToast();

  const navigate = useNavigate();

  const extendFundraisingModal = useDisclosure();
  const uploadSubscriptionTemplateModal = useDisclosure();

  const isSpace = useMatch(routes.space.href + '/*');

  const isAllInSubscriptionWorkflow =
    operation.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;

  const handleOperationDraftClick = (): void => {
    if (isSpace) {
      return handleOperationCurrentClick();
    }

    if (!companyId) return;

    navigate(
      generatePath(
        operation.fundraising?.type === FundraisingType.PRIVATE
          ? routes.company.newFundraising.private.href +
              '/' +
              routes.company.newFundraising.private.edit.href
          : routes.company.newFundraising.crowdfunding.href +
              '/' +
              routes.company.newFundraising.crowdfunding.edit.href,
        {
          operationId: operation.id,
        },
      ),
    );
  };

  const handleOperationCurrentClick = (): void => {
    const rowCompanyId = isSpace && operation.company?.id ? operation.company.id : companyId;
    if (rowCompanyId) {
      const relPath = generatePath(routes.company.fundraising.href, {
        companyId: rowCompanyId,
        operationId: operation.id,
      });
      navigate(
        isSpace
          ? resolvePath(
              relPath,
              generatePath(routes.space.company.full, { companyId: rowCompanyId }),
            ).pathname
          : relPath,
      );
    }

    return;
  };

  const handleRowClick = (): void => {
    switch (operation.status) {
      case OperationStatus.DRAFT: {
        handleOperationDraftClick();
        break;
      }

      default: {
        handleOperationCurrentClick();
        break;
      }
    }
  };

  const handlePECDownload = (operationId: Operation['id']): void => {
    setIsPECLoading(true);
    axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/exportRegulatoryReportCsv',
        { operationId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'text/csv',
          },
          responseType: 'text',
        },
      )
      .then((response) => {
        downloadCsv(response.data, `pec-${operationId}.csv`);
        setIsPECLoading(false);
      })
      .catch(() => {
        setIsPECLoading(false);
      });
  };

  const handleArchiveDownload = (operationId: Operation['id']): void => {
    setIsArchiveLoading(true);
    axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/exportSubscriptionsReportZip',
        {
          operationId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/zip',
          },
          responseType: 'blob',
        },
      )
      .then((response) => {
        downloadFile(response.data, `archive-files-${operationId}.zip`);
        setIsArchiveLoading(false);
      })
      .catch(() => {
        setIsArchiveLoading(false);
      });
  };

  const handleSignedCertificatesDownload = (operationId: Operation['id']): void => {
    setIsSignedCertificatesLoading(true);
    axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/exportSignedCertificateDocuments',
        { operationId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            Accept: 'application/zip',
          },
          responseType: 'blob',
        },
      )
      .then((response) => {
        downloadFile(response.data, `signed-certificates-${operationId}.zip`);
        setIsSignedCertificatesLoading(false);
      })
      .catch(() => {
        errorToast({ title: t('ArchiveDownloadFailed') });
        setIsSignedCertificatesLoading(false);
      });
  };
  const endDate = operation.closingDate || operation.endSubscriptionDate;

  const canExportPEC = isSpace && operation.status !== OperationStatus.DRAFT;
  const isClosed =
    isSpace &&
    [OperationStatus.CLOSED, OperationStatus.REVIEWED, OperationStatus.FINALIZED].includes(
      operation.status,
    );
  const canExportArchive = isClosed;
  const canUploadSubscriptionTemplate = !isClosed;
  const canExportSignedCertificates = isSpace && operation.status === OperationStatus.FINALIZED;
  const canExtendOperation = isSpace && operation.status === OperationStatus.STARTED && endDate;

  const operationSubTitle =
    operation.assetType === AssetType.BOND ? t('BondIssuance') : t('CapitalIncrease');

  return (
    <Tr role="button" onClick={!isSpace ? handleRowClick : noop}>
      <Td role="button" onClick={isSpace ? handleRowClick : noop}>
        <Text fontWeight="600">{operation.name}</Text>
        <Text color="gray.500" fontSize="xs" fontWeight="400">
          {operationSubTitle}
        </Text>
      </Td>
      {isSpace && (
        <>
          <Td>
            <Menu>
              <MenuButton
                as={Button}
                fontSize="sm"
                isDisabled={false}
                isLoading={isSignedCertificatesLoading || isPECLoading || isArchiveLoading}
                rightIcon={<Icon as={DotsVerticalIcon} boxSize="4" />}
                variant="secondary"
              >
                {t('Action', { nb: 2 })}
              </MenuButton>
              <MenuList>
                <MenuItem
                  aria-label="extend operation"
                  fontSize="sm"
                  icon={<Icon as={ClockIcon} boxSize="5" color="gray.500" />}
                  isDisabled={!canExtendOperation}
                  onClick={extendFundraisingModal.onOpen}
                >
                  {t('ExtendOperation')}
                </MenuItem>
                <MenuItem
                  aria-label="import subscription template"
                  fontSize="sm"
                  icon={<Icon as={UploadIcon} boxSize="5" color="gray.500" />}
                  isDisabled={!canUploadSubscriptionTemplate}
                  onClick={uploadSubscriptionTemplateModal.onOpen}
                >
                  {t('ImportSubscriptionTemplate')}
                </MenuItem>
                <MenuItem
                  aria-label="Download archive of signed certificates"
                  fontSize="sm"
                  icon={<Icon as={ShieldCheckIcon} boxSize="5" color="gray.500" />}
                  isDisabled={!canExportSignedCertificates || isSignedCertificatesLoading}
                  onClick={(): void => handleSignedCertificatesDownload(operation.id)}
                >
                  {t('DownloadSignedCertificates')}
                </MenuItem>
                <MenuItem
                  aria-label="Download PEC file"
                  fontSize="sm"
                  icon={<Icon as={TableIcon} boxSize="5" color="gray.500" />}
                  isDisabled={!canExportPEC || isPECLoading}
                  onClick={(): void => handlePECDownload(operation.id)}
                >
                  {t('DownloadPECFile')}
                </MenuItem>
                <MenuItem
                  aria-label="Download archive of operation file"
                  fontSize="sm"
                  icon={<Icon as={ArchiveIcon} boxSize="5" color="gray.500" />}
                  isDisabled={!canExportArchive || isArchiveLoading}
                  onClick={(): void => handleArchiveDownload(operation.id)}
                >
                  {t('DownloadOperationArchive')}
                </MenuItem>
              </MenuList>
              {canExtendOperation && extendFundraisingModal.isOpen && (
                <ExtendOperationModal
                  isOpen={extendFundraisingModal.isOpen}
                  operationId={operation.id}
                  operationType={operation.type}
                  onClose={extendFundraisingModal.onClose}
                />
              )}
              {canUploadSubscriptionTemplate && uploadSubscriptionTemplateModal.isOpen && (
                <UploadSubscriptionTemplateModal
                  isOpen={uploadSubscriptionTemplateModal.isOpen}
                  operationId={operation.id}
                  onClose={uploadSubscriptionTemplateModal.onClose}
                />
              )}
            </Menu>
          </Td>
          <Td>
            <OperationBadge
              isAllInSubscriptionWorkflow={isAllInSubscriptionWorkflow}
              operationStatus={operation.status}
            />
          </Td>
          <Td>
            <Text fontWeight="600">{operation.company?.name || '-'}</Text>
          </Td>
        </>
      )}
      <Td isNumeric>
        {operation.fundraising?.hardCap
          ? formatNumberCurrency(operation?.fundraising?.hardCap)
          : '-'}
      </Td>
      <Td>
        <Stack alignItems="center" direction="row">
          <Icon as={UserGroupIcon} boxSize="18px" color="gray.900" />
          <Text>{operation.subscriptionCount}</Text>
        </Stack>
      </Td>
      <Td>{endDate ? formatDate(endDate, 'll') : '-'}</Td>
      <Td textAlign="right">
        <Button
          fontSize="sm"
          rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
          variant="secondary"
          onClick={isSpace ? handleRowClick : noop}
        >
          {t('ShowDetails')}
        </Button>
      </Td>
    </Tr>
  );
}

export type OperationTableFundraisingRowProps = Props;
