import { Button, Stack } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { CompanyStatus, CompanyType, useGetCompanyQuery } from '@blockpulse3/graphql/hooks';

import { OperationRowsCurrent } from './OperationRows/OperationRowsCurrent';
import { OperationRowDisplayType } from './OperationRows/types';

type Props = {
  displayType: OperationRowDisplayType;
  limit?: number;
};

export function OperationRowsPreview({ displayType, limit }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const navigate = useNavigate();

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;
  const isDraftSPV = company?.type === CompanyType.SPV && company?.status !== CompanyStatus.RUNNING;

  const handleSeeAllClick = (): void => {
    navigate(routes.company.operations.href);
  };

  return (
    <Stack spacing="4">
      <OperationRowsCurrent displayType={displayType} limit={limit} />
      {!isDraftSPV && (
        <Button variant="link" onClick={handleSeeAllClick}>
          {t('SeeAllOperations')}
        </Button>
      )}
    </Stack>
  );
}
