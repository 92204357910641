import {
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  useRadioGroup,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  CompanyCapitalType,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from '@blockpulse3/graphql/hooks';
import { ErrorMessage, Input } from '@blockpulse3/ui/commons';

import { schemaCompleteCompany } from './schema';
import { ICompleteCompany } from './types';

type Props = {
  /* ** Callback on form submit ** */
  onSubmit: () => void;
};

/**
 * CompleteCompanyForm.
 * Complete company informations about capital information.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function CompleteCompanyForm({ onSubmit = noop }: Props): JSX.Element {
  const capitalInputRef = useRef<HTMLDivElement>(null);

  const t = useTranslations();

  const { companyId = '' } = useParams();

  /* ** From cache ** */
  const { data } = useGetCompanyQuery({ variables: { companyId }, skip: !companyId });
  const company = data?.company;

  const [updateCompanyInformations] = useUpdateCompanyMutation();

  const { register, handleSubmit, control, formState, setValue, getValues, clearErrors, reset } =
    useForm<ICompleteCompany>({
      resolver: yupResolver(schemaCompleteCompany),
      defaultValues: {
        nominalValue: company?.nominalValue || 0,
        shareCapital: company?.shareCapital || 0,
        capitalType: company?.capitalType || undefined,
        minimumShareCapital: company?.minimumShareCapital || null,
        maximumShareCapital: company?.maximumShareCapital || null,
      },
    });

  const { getRadioProps } = useRadioGroup({
    name: 'capitalType',
    onChange: (value: CompanyCapitalType): void => {
      setValue('capitalType', value);
      setValue('minimumShareCapital', null);
      setValue('maximumShareCapital', null);
      clearErrors('minimumShareCapital');
      clearErrors('maximumShareCapital');
      clearErrors('capitalType');
    },
  });

  useEffect(() => {
    reset({
      nominalValue: company?.nominalValue || 0,
      shareCapital: company?.shareCapital || 0,
      capitalType: company?.capitalType || undefined,
      minimumShareCapital: company?.minimumShareCapital || null,
      maximumShareCapital: company?.maximumShareCapital || null,
    });
  }, [company]);

  const handleFormSubmit = (data: ICompleteCompany): void => {
    if (companyId) {
      updateCompanyInformations({
        variables: {
          updateCompanyInput: {
            companyId,
            nominalValue: data.nominalValue,
            capitalType: data.capitalType,
            minimumShareCapital: data.minimumShareCapital || null,
            maximumShareCapital: data.maximumShareCapital || null,
            shareCapital: data.shareCapital,
          },
        },
        onCompleted: () => {
          onSubmit();
        },
      });
    }
  };

  useEffect(() => {
    if (capitalInputRef.current) {
      capitalInputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [capitalInputRef.current]);

  return (
    <form id="complete-company" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="4">
        <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
          <FormControl isInvalid={!!formState.errors?.nominalValue}>
            <FormLabel htmlFor="nominalValue">{t('NominalValue')}</FormLabel>
            <InputGroup>
              <Input id="nominalValue" step="0.01" type="number" {...register('nominalValue')} />
              <InputRightElement color="gray.500">€</InputRightElement>
            </InputGroup>
            <ErrorMessage error={formState.errors?.nominalValue} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors?.shareCapital}>
            <FormLabel htmlFor="shareCapital">{t('ShareCapital')}</FormLabel>
            <InputGroup>
              <Input id="shareCapital" step="0.01" type="number" {...register('shareCapital')} />
              <InputRightElement color="gray.500">€</InputRightElement>
            </InputGroup>
            <ErrorMessage error={formState.errors.shareCapital} />
          </FormControl>
        </Stack>
        <Controller
          control={control}
          name="capitalType"
          render={({ field }): JSX.Element => (
            <FormControl isInvalid={!!formState.errors?.capitalType}>
              <FormLabel>{t('CapitalType')}</FormLabel>
              <RadioGroup
                as={Stack}
                direction={{ base: 'column', md: 'row' }}
                id="capitalType"
                spacing="4"
                {...field}
              >
                <Radio variant="choice" {...getRadioProps({ value: CompanyCapitalType.FIXED })}>
                  {t('FixedCapital')}
                </Radio>
                <Radio variant="choice" {...getRadioProps({ value: CompanyCapitalType.VARIABLE })}>
                  {t('VariableCapital')}
                </Radio>
              </RadioGroup>
              <ErrorMessage error={formState.errors?.capitalType} />
            </FormControl>
          )}
        />
        {getValues('capitalType') === CompanyCapitalType.VARIABLE && (
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.minimumShareCapital} ref={capitalInputRef}>
              <FormLabel htmlFor="minimumShareCapital">{t('MinCapital')}</FormLabel>
              <InputGroup>
                <Input
                  id="minimumShareCapital"
                  step="0.01"
                  type="number"
                  {...register('minimumShareCapital')}
                />
                <InputRightElement color="gray.500">€</InputRightElement>
              </InputGroup>
              <ErrorMessage error={formState.errors.minimumShareCapital} />
            </FormControl>
            <FormControl isInvalid={!!formState.errors?.maximumShareCapital}>
              <FormLabel htmlFor="maximumShareCapital">{t('MaxCapital')}</FormLabel>
              <InputGroup>
                <Input
                  id="maximumShareCapital"
                  step="0.01"
                  type="number"
                  {...register('maximumShareCapital')}
                />
                <InputRightElement color="gray.500">€</InputRightElement>
              </InputGroup>
              <ErrorMessage error={formState.errors.maximumShareCapital} />
            </FormControl>
          </Stack>
        )}
      </Stack>
    </form>
  );
}

export type CompleteCompanyFormProps = Props;
