import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { OperationStatus } from '@blockpulse3/graphql/hooks';

import { OperationRow } from '../../types';
import { OperationCardTemplate } from './OperationCardTemplate';

type Props = {
  operation: OperationRow;
};

export function OperationCardOpportunity({ operation }: Props): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const navigate = useNavigate();

  const isSpace = useMatch(routes.space.href + '/*');
  const isOperationPage = useMatch(
    routes.company.href + '/' + routes.company.operations.href + '/*',
  );
  const routePrefix = isOperationPage ? '../' : '';

  const handleOperationDraftClick = (): void => {
    if (isSpace) {
      return handleOperationCurrentClick();
    }

    if (!companyId) return;

    navigate(
      routePrefix +
        generatePath(
          routes.company.newOpportunity.href + '/' + routes.company.newOpportunity.edit.href,
          {
            companyId,
            operationId: operation.id,
          },
        ),
      { relative: 'path' },
    );
  };

  const handleOperationCurrentClick = (): void => {
    const rowCompanyId = isSpace && operation.company?.id ? operation.company.id : companyId;
    if (rowCompanyId) {
      const relPath = generatePath(routes.company.opportunity.href, {
        companyId: rowCompanyId,
        operationId: operation.id,
      });

      if (isSpace) {
        navigate(
          resolvePath(relPath, generatePath(routes.space.company.full, { companyId: rowCompanyId }))
            .pathname,
        );
      } else {
        navigate(routePrefix + relPath, { relative: 'path' });
      }
    }

    return;
  };

  const handleRowClick = (): void => {
    switch (operation.status) {
      case OperationStatus.DRAFT: {
        handleOperationDraftClick();
        break;
      }

      default: {
        handleOperationCurrentClick();
        break;
      }
    }
  };

  if (operation.data?.__typename !== 'OpportunityData') return null;

  return (
    <OperationCardTemplate
      operation={operation}
      operationSubtitle={t('PurchaseOpportunity')}
      totalAmount={operation.data.totalApprovedAmount}
      onClick={handleRowClick}
    />
  );
}
