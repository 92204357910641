import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { IdentityType, useGetSubscriptionQuery } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';

export function IntentPanelIdentityCard(): JSX.Element {
  const { subscriptionId = '' } = useParams();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  if (loading || !data || error) {
    return (
      <Stack px="4">
        <Skeleton h="80px" />
      </Stack>
    );
  }

  const { buyerIdentity } = data.subscription;
  const isLegal = buyerIdentity?.type === IdentityType.COMPANY;

  const address = isLegal
    ? buyerIdentity?.companyIdentity?.address
    : buyerIdentity?.individualIdentity?.address;

  const name = `${buyerIdentity?.name}${isLegal ? ` (${buyerIdentity?.identifier})` : ''}`;

  const birthdate = buyerIdentity?.individualIdentity?.birthdate;

  return (
    <IdentityCard px="4">
      <IdentityAvatar boxSize="14" src={buyerIdentity?.profilePicture} type={buyerIdentity?.type} />
      <IdentityCardTitle isChecked={buyerIdentity?.isVerified}>
        <Text>{name}</Text>
      </IdentityCardTitle>
      <IdentityCardDescription>
        {birthdate && <Text>{formatDate(birthdate)}</Text>}
        <Text>{address?.formatted}</Text>
        <Text>{buyerIdentity?.email}</Text>
        <Text>{buyerIdentity?.phone}</Text>
      </IdentityCardDescription>
    </IdentityCard>
  );
}
