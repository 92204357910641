import { useTranslations } from 'use-intl';

import { IUseBadge, useBadge } from '@blockpulse3/ui/ui-hooks';

import { OptionGrantStatus } from './getOptionStatus';

export const useGrantBadge = (): IUseBadge => {
  const t = useTranslations();
  return useBadge(
    undefined,
    [
      {
        value: OptionGrantStatus.GRANTED,
        color: 'gray',
        label: t('Assigned'),
      },
      {
        value: OptionGrantStatus.SUBSCRIBED,
        color: 'yellow',
        label: t('Subscribed'),
      },
      {
        value: OptionGrantStatus.VESTING,
        color: 'blue',
        label: t('VestingInProgress'),
      },
      {
        value: OptionGrantStatus.VESTED,
        color: 'blue',
        label: t('Vested'),
      },
      {
        value: OptionGrantStatus.EXERCISED,
        color: 'green',
        label: t('Exercised'),
      },
      {
        value: OptionGrantStatus.OBSOLETE,
        color: 'red',
        label: t('Obsolete'),
      },
    ],
    { color: 'gray', label: t('Assigned') },
  );
};
