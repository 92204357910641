import { Card, CardBody, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { IdentificationIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { IntlUboStatusValues } from '@blockpulse3/data/locales/types';
import { UboInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatCountry, formatDate } from '@blockpulse3/helpers';

type Props = {
  ubo: UboInfosFragment;
  index: number;
};

export function UltimateBeneficialOwnerCard({ ubo, index }: Props): JSX.Element {
  const t = useTranslations();
  const i18nUboStatus = useTranslations('UboStatusValues');

  return (
    <Card key={ubo.id}>
      <CardBody>
        <HStack pb="2">
          <Icon
            as={IdentificationIcon}
            bgColor="gray.50"
            border="1px"
            borderColor="gray.200"
            boxSize="24px"
          />
          <Text fontSize="lg" fontWeight="600">
            {t('RecipientIndex', {
              index: index + 1,
            })}
          </Text>
        </HStack>
        <Stack>
          <Stack pb="2" spacing="0">
            <Text fontWeight="500">{`${ubo.firstName} ${ubo.lastName}`}</Text>
            {ubo?.individualIdentity?.email && (
              <Text color="gray.600">{ubo.individualIdentity.email}</Text>
            )}
          </Stack>
          <HStack justify="space-between">
            <Text color="gray.600">{t('BirthDate')}</Text>
            <Text fontWeight="500">{formatDate(ubo.birthdate)}</Text>
          </HStack>
          <HStack justify="space-between">
            <Text color="gray.600">{t('BirthCity')}</Text>
            <Text fontWeight="500">{ubo.birthplace}</Text>
          </HStack>
          <HStack justify="space-between">
            <Text color="gray.600">{t('BirthCountry')}</Text>
            <Text fontWeight="500">{formatCountry(ubo.birthCountry)}</Text>
          </HStack>
          <HStack justify="space-between">
            <Text color="gray.600">{t('BirthPostalCode')}</Text>
            <Text fontWeight="500">{ubo.birthCityPostalCode}</Text>
          </HStack>
          <HStack justify="space-between">
            <Text color="gray.600">{t('Status', { nb: 1 })}</Text>
            <Text fontWeight="500">{i18nUboStatus(ubo.role as IntlUboStatusValues)}</Text>
          </HStack>
          <HStack justify="space-between">
            <Text color="gray.600">{t('OwnershipPercentage')}</Text>
            <Text fontWeight="500">{`${ubo.sharePercentage}%`}</Text>
          </HStack>
          {ubo.isDirect && (
            <HStack justify="space-between">
              <Text>{t('CapitalDirectlyHeld')}</Text>
            </HStack>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
}
