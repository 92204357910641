import {
  GetMemberRolesOnResourceInput,
  MemberRole,
  useGetMemberRolesOnResourceQuery,
} from '@blockpulse3/graphql/hooks';

import { useAuthUser } from './user';

/**
 * useManagerRole.
 * Perform query to get the user role from a managed identity by a space. Check if the role is
 * included in the ones passed in arguments.
 *
 * @param {GetMemberRolesOnResourceInput} getMemberRolesOnResourceInput
 * @param {MemberRole[]} roles
 * @returns {boolean}
 */
export function useManagerRole(
  getMemberRolesOnResourceInput: GetMemberRolesOnResourceInput,
  authorizedRoles: MemberRole[] = [MemberRole.OWNER, MemberRole.ADMIN],
): boolean {
  const { user } = useAuthUser();

  const { data, loading, error } = useGetMemberRolesOnResourceQuery({
    variables: { getMemberRolesOnResourceInput },
    skip: !user || user?.isSuperAdmin,
  });

  if (user?.isSuperAdmin) {
    return true;
  }

  if (loading) {
    return false;
  }

  if (!data?.getMemberRolesOnResource || error) {
    return false;
  }

  const memberRoles = data?.getMemberRolesOnResource;

  return authorizedRoles.some((role) => memberRoles.includes(role));
}
