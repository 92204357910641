import { useParams } from 'react-router-dom';

import { CompanyType, useGetCompanyQuery } from '@blockpulse3/graphql/hooks';

import { PartialShareTransferRegisters } from './PartialShareTransferRegisters';
import { ShareTransferRegister } from './ShareTransferRegister';

type Props = unknown;

/**
 * ShareTransferRegisterView.
 * RMT view.
 *
 * @returns {JSX.Element}
 */
export function ShareTransferRegisterView(): JSX.Element {
  const { companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  if (company?.type === CompanyType.PARTIAL) {
    return <PartialShareTransferRegisters />;
  }

  return <ShareTransferRegister />;
}

export type ShareTransferRegisterViewProps = Props;
