import { ApolloError, QueryResult } from '@apollo/client';
import { useMatch, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import {
  GetAllOperationsCountQuery,
  GetOperationsCountQuery,
  GetSpaceOperationsCountQuery,
  SpaceType,
  useGetAllOperationsCountQuery,
  useGetOperationsCountQuery,
  useGetSpaceOperationsCountQuery,
  useGetSpaceQuery,
} from '@blockpulse3/graphql/hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type OperationsCountQueryResult = QueryResult<
  GetOperationsCountQuery | GetAllOperationsCountQuery | GetSpaceOperationsCountQuery,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>;

type OperationsCountData = { drafts: number; passed: number; started: number };

type OperationsCountQuery = {
  operationsCount: OperationsCountData;
  operationsCountLoading: boolean;
  operationsCountError: ApolloError | undefined;
  operationsCountRefetch: () => void;
};

export function useGetOperationsCount(): OperationsCountQuery {
  const { spaceId: authSpaceId = '' } = useAuthUser();

  const { companyId = '', spaceId = authSpaceId } = useParams();

  const isSpace = useMatch(routes.space.href + '/*');

  const spaceReq = useGetSpaceQuery({
    variables: { spaceId },
    skip: !spaceId,
  });
  const space = spaceReq?.data?.space;
  const isSpaceGlobal = space?.type === SpaceType.GLOBAL;

  // Admin query
  const allOperationsCount = useGetAllOperationsCountQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isSpace || !space || !isSpaceGlobal,
  });

  // Space query
  const spaceOperationsCount = useGetSpaceOperationsCountQuery({
    variables: { spaceId },
    fetchPolicy: 'cache-and-network',
    skip: !isSpace || !space || !spaceId || isSpaceGlobal,
  });

  // Company query
  const companyOperationsCount = useGetOperationsCountQuery({
    variables: { companyId: companyId || '' },
    fetchPolicy: 'cache-and-network',
    skip: !!isSpace || !companyId,
  });

  const {
    data,
    loading: operationsCountLoading,
    error: operationsCountError,
    refetch: operationsCountRefetch,
  } = ((allOperationsCount.called && allOperationsCount) ||
    (spaceOperationsCount.called && spaceOperationsCount) ||
    (companyOperationsCount.called && companyOperationsCount)) as OperationsCountQueryResult;

  let operationsCount: OperationsCountData = { drafts: 0, passed: 0, started: 0 };
  if (data) {
    if ('getOperationsCount' in data) {
      operationsCount = data.getOperationsCount;
    } else if ('getAllOperationsCount' in data) {
      operationsCount = data.getAllOperationsCount;
    } else if ('getSpaceOperationsCount' in data) {
      operationsCount = data.getSpaceOperationsCount;
    }
  }

  return {
    operationsCount,
    operationsCountLoading,
    operationsCountError,
    operationsCountRefetch,
  };
}
