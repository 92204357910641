import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Progress,
  Skeleton,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tooltip,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetCompanyCaptableQuery } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberInt } from '@blockpulse3/helpers';
import { CompanyIdentityAvatar, ErrorQueryCard } from '@blockpulse3/ui/commons';

type Props = unknown;

/**
 * CapTableInformations.
 *
 * @returns {JSX.Element}
 */
export function CapTableInformations(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { data, loading, error } = useGetCompanyCaptableQuery({
    variables: { companyId },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="300px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="300px" />;
  }

  if (!data) {
    return <ErrorQueryCard />;
  }

  const { name, shareCapital, nominalValue, capTable, identity } = data.company || {};
  const { stockTotalSupply, fullyDilutedTotalSupply, fullyDilutedShareCapital } = capTable || {
    stockTotalSupply: 0,
  };

  return (
    <Card>
      <CardHeader as={HStack}>
        <CompanyIdentityAvatar boxSize="20" src={identity?.profilePicture} />
        <Heading color="gray.800" fontSize="xl" fontWeight="semibold" paddingLeft="4">
          {name}
        </Heading>
      </CardHeader>
      <CardBody as={Stack} spacing="6">
        <Progress borderRadius="base" outlineColor="#00f" value={100} />
        <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
          <Stat bg="gray.50" justifyContent="start" p="4">
            <StatLabel color="gray.500" fontSize="md" fontWeight="500">
              {t('ShareCapital')}
            </StatLabel>
            <StatNumber>{formatNumberCurrency(shareCapital || 0)}</StatNumber>
            <Tooltip
              hasArrow
              label={t('FullyDiluted', { gender: 'male', nb: 1 })}
              placement="right-end"
            >
              <StatHelpText width="fit-content">
                {formatNumberCurrency(fullyDilutedShareCapital || 0)}
              </StatHelpText>
            </Tooltip>
          </Stat>
          <Stat bg="gray.50" p="4">
            <StatLabel color="gray.500" fontSize="md" fontWeight="500">
              {t('SharesCount')}
            </StatLabel>
            <StatNumber>{formatNumberInt(stockTotalSupply || 0)}</StatNumber>
            <Tooltip
              hasArrow
              placement="right-end"
              label={t('FullyDiluted', {
                gender: 'male',
                nb: stockTotalSupply || 0,
              })}
            >
              <StatHelpText width="fit-content">
                {formatNumberInt(fullyDilutedTotalSupply || 0)}
              </StatHelpText>
            </Tooltip>
          </Stat>
          <Stat bg="gray.50" p="4">
            <StatLabel color="gray.500" fontSize="md" fontWeight="500">
              {t('NominalValue')}
            </StatLabel>
            <StatNumber>{formatNumberCurrency(nominalValue || 0)}</StatNumber>
          </Stat>
        </Stack>
      </CardBody>
    </Card>
  );
}

export type CapTableInformationsProps = Props;
