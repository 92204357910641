import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowNarrowRightIcon, ClockIcon, UserGroupIcon } from '@heroicons/react/outline';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import { OperationStatus } from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { OperationBadge } from '@blockpulse3/ui/commons';

import { ExtendOperationModal } from '../../../ExtendOperationModal';
import { OperationRow } from '../../types';

type Props = {
  operation: OperationRow;
};

export function OperationTableSecondaryRow({ operation }: Props): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const navigate = useNavigate();

  const isSpace = useMatch(routes.space.href + '/*');

  const extendOperationModal = useDisclosure();

  const handleOperationDraftClick = (): void => {
    if (isSpace) {
      return handleOperationCurrentClick();
    }

    if (!companyId) return;

    navigate(
      generatePath(routes.company.newSecondary.href + '/' + routes.company.newSecondary.edit.href, {
        operationId: operation.id,
      }),
    );
  };

  const handleOperationCurrentClick = (): void => {
    const rowCompanyId = isSpace && operation.company?.id ? operation.company.id : companyId;
    if (rowCompanyId) {
      const relPath = generatePath(routes.company.secondary.href, {
        companyId: rowCompanyId,
        operationId: operation.id,
      });
      navigate(
        isSpace
          ? resolvePath(
              relPath,
              generatePath(routes.space.company.full, { companyId: rowCompanyId }),
            ).pathname
          : relPath,
      );
    }

    return;
  };

  const handleRowClick = (): void => {
    switch (operation.status) {
      case OperationStatus.DRAFT: {
        handleOperationDraftClick();
        break;
      }

      default: {
        handleOperationCurrentClick();
        break;
      }
    }
  };

  const endDate = operation.closingDate || operation.endSubscriptionDate;
  const canExtendOperation = isSpace && operation.status === OperationStatus.STARTED && endDate;

  if (operation.data?.__typename !== 'SecondaryData') return null;

  return (
    <Tr role="button" onClick={!isSpace ? handleRowClick : noop}>
      <Td onClick={isSpace ? handleRowClick : noop}>
        <Text fontWeight="600">{operation.name}</Text>
        <Text color="gray.500" fontSize="xs" fontWeight="400">
          {t('SecondaryMarket')}
        </Text>
      </Td>
      {isSpace && (
        <>
          <Td>
            <Menu>
              <MenuButton
                as={Button}
                fontSize="sm"
                rightIcon={<Icon as={DotsVerticalIcon} boxSize="4" />}
                variant="secondary"
              >
                {t('Action', { nb: 2 })}
              </MenuButton>
              <MenuList>
                <MenuItem
                  aria-label="extend operation"
                  fontSize="sm"
                  icon={<Icon as={ClockIcon} boxSize="5" color="gray.500" />}
                  isDisabled={!canExtendOperation}
                  onClick={extendOperationModal.onOpen}
                >
                  {t('ExtendOperation')}
                </MenuItem>
              </MenuList>
              {canExtendOperation && extendOperationModal.isOpen && (
                <ExtendOperationModal
                  isOpen={extendOperationModal.isOpen}
                  operationId={operation.id}
                  operationType={operation.type}
                  onClose={extendOperationModal.onClose}
                />
              )}
            </Menu>
          </Td>
          <Td>
            <OperationBadge operationStatus={operation.status} />
          </Td>
          <Td>
            <Text fontWeight="600">{operation.company?.name || '-'}</Text>
          </Td>
        </>
      )}
      <Td isNumeric>
        {operation.data.tradedAmount ? formatNumberCurrency(operation.data.tradedAmount) : '-'}
      </Td>
      <Td>
        <Stack alignItems="center" direction="row">
          <Icon as={UserGroupIcon} boxSize="18px" color="gray.900" />
          <Text>{operation.subscriptionCount}</Text>
        </Stack>
      </Td>
      <Td>{endDate ? formatDate(endDate, 'll') : '-'}</Td>
      <Td textAlign="right">
        <Button
          fontSize="sm"
          rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
          variant="secondary"
          onClick={isSpace ? handleRowClick : noop}
        >
          {t('ShowDetails')}
        </Button>
      </Td>
    </Tr>
  );
}

export type OperationTableFundraisingRowProps = Props;
