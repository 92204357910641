import { useDisclosure } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { OperationStatus } from '@blockpulse3/graphql/hooks';
import { CompleteOperationModal } from '@blockpulse3/web-client/operation/commons';

import { useGetOperations } from '../../hook';
import { OperationRow } from '../../types';
import { OperationCardTemplate } from './OperationCardTemplate';

type Props = {
  operation: OperationRow;
};

export function OperationCardUnknown({ operation }: Props): JSX.Element {
  const t = useTranslations();

  const { operationsRefetch } = useGetOperations(OperationStatus.FINALIZED);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOperationCompleted = (): void => {
    operationsRefetch();
    onClose();
  };

  const handleRowClick = (): void => {
    onOpen();
  };

  return (
    <>
      <OperationCardTemplate
        isIncomplete={true}
        operation={operation}
        operationSubtitle={t('IncompleteF')}
        onClick={handleRowClick}
      />
      {isOpen && (
        <CompleteOperationModal
          isOpen={isOpen}
          operationId={operation.id}
          onClose={onClose}
          onCompleted={handleOperationCompleted}
        />
      )}
    </>
  );
}
