import {
  Badge,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  HStack,
  Icon,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useCheckboxGroup,
  useDisclosure,
} from '@chakra-ui/react';
import { FilterIcon } from '@heroicons/react/outline';
import { ActionMeta, AsyncSelect, MultiValue } from 'chakra-react-select';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlSubscriptionFilterKeyValues } from '@blockpulse3/data/locales/types';
import {
  SubscriptionFilterType,
  SubscriptionsFilterInput,
  useGetOperationQuery,
  useGetTagsLazyQuery,
  useGetTagsQuery,
} from '@blockpulse3/graphql/hooks';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  TagInput,
  TagMultiValue,
  TagOption,
} from '@blockpulse3/ui/commons';

import {
  buyerSubscriptionFilterIdentity,
  getSubscriptionFilterStep,
  getSubscriptionFilterTags,
  sellerSubscriptionFilterIdentity,
  subscriptionFilterHoldingMethod,
} from './utils';

type Props = {
  /* ** Default checked filters ** */
  defaultValue: SubscriptionsFilterInput[];
  /* ** Callback on filter submit ** */
  onSubmit: (filters: SubscriptionsFilterInput[]) => void;
};

export function SecondaryOperationTransactionTableFilters({
  defaultValue = [],
  onSubmit,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nSubscriptionFilterKeys = useTranslations('SubscriptionFilterKeyValues');

  const { companyId = '', operationId = '' } = useParams();

  /* ** Filter modal disclosure ** */
  const { isOpen, onClose, onToggle } = useDisclosure();

  /* ** Fetch on inputChange ** */
  const [fetchTags] = useGetTagsLazyQuery();

  /* ** Checkbox group, holding all filters ** */
  const checkboxGroup = useCheckboxGroup();
  const checkboxFilters = defaultValue.map((filter) => filter.value || '');

  /* ** Build filter inputs for the submit and modal display ** */
  const operationReq = useGetOperationQuery({ variables: { operationId } });
  const operation = operationReq.data?.operation;

  const subscriptionFiltersTags = getSubscriptionFilterTags(operation);
  const subscriptionFiltersStep = getSubscriptionFilterStep(operation);

  /* ** Fetch available company tags to read their color ** */
  const companyTagsReq = useGetTagsQuery({
    variables: {
      getTagsInput: { companyId: companyId || operation?.company?.id || '', operationId },
    },
    skip: !companyId && !operation?.company?.id,
  });
  const companyTags = companyTagsReq.data?.getTags || [];

  /* ** Tag filters sub group ** */
  const tagFilters = defaultValue
    .filter((filter) => filter.type === SubscriptionFilterType.TAGS)
    .map((filter) => filter.value || '');

  /* ** Tag filters as `<AsyncSelect />` options ** */
  const tagFilterOptions = companyTags
    .filter((tag) => tagFilters.includes(tag.id))
    .map((tag) => ({ label: tag.name, color: tag.color, value: tag.id }));

  /* ** Promise callback to load tags on input change ** */
  const loadTagOptions = useCallback(
    async (inputValue: string) => {
      const req = await fetchTags({
        variables: {
          getTagsInput: { companyId: companyId || operation?.company?.id || '', operationId },
        },
        fetchPolicy: 'cache-and-network',
      });
      const companyTags = req.data?.getTags || [];

      return companyTags
        .filter((tag) => tag.name.toLowerCase().includes(inputValue.toLowerCase()))
        .map((tag) => ({ label: tag.name, value: tag.id, color: tag.color }));
    },
    [fetchTags, operationId, companyId, operation?.company?.id],
  );

  const getFilterLabel = (name: string): string => {
    return i18nSubscriptionFilterKeys(name as IntlSubscriptionFilterKeyValues);
  };

  const getNoOptionsMessage = (): string => {
    return t('NoExistingTag');
  };

  /*
   * All current filters are saved in `checkboxGroup`. This method simulate checkbox triggers for
   * tag changes.
   * This avoids separate logic for tags.
   */
  const handleTagChange = (_: MultiValue<TagOption>, action: ActionMeta<TagOption>): void => {
    switch (action.action) {
      case 'select-option': {
        checkboxGroup.setValue((prevFilters) => [...prevFilters, action.option?.value || '']);
        break;
      }
      case 'pop-value':
      case 'remove-value': {
        if (action.removedValue) {
          checkboxGroup.setValue((prevFilter) =>
            prevFilter.filter((f) => f !== action.removedValue.value),
          );
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleFilterModalClose = (): void => {
    checkboxGroup.setValue(checkboxFilters);
    onClose();
  };

  const handleFilterModalSubmit = (): void => {
    const allFilters = [
      ...sellerSubscriptionFilterIdentity,
      ...buyerSubscriptionFilterIdentity,
      ...subscriptionFilterHoldingMethod,
      ...subscriptionFiltersTags,
      ...subscriptionFiltersStep,
    ];
    /* ** Transform `(string | number)[]` checkbox value into `SubscriptionsFilterInput[]` ** */
    const filters = allFilters.filter((option) => checkboxGroup.value.includes(option.value || ''));
    onSubmit(filters);
    onClose();
  };

  /* ** Remove investStatus and searchValue from filter count */
  const filtersCount = defaultValue.filter(
    (filter) => !['investStatus', 'searchValue'].includes(filter.name),
  ).length;

  return (
    <>
      <Button
        position="relative"
        rightIcon={<Icon as={FilterIcon} />}
        variant="secondary"
        onClick={onToggle}
      >
        {t('FilterAction')}
        <Badge
          bg="secondary"
          borderRadius="full"
          color="white"
          fontSize="xs"
          hidden={filtersCount === 0}
          paddingX="1"
          position="absolute"
          right="6px"
          top="4px"
        >
          {filtersCount}
        </Badge>
      </Button>
      <ResponsiveModal isOpen={isOpen} size="xl" onClose={handleFilterModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Filter', { nb: 2 })}</ModalHeader>
          <ModalBody>
            <CheckboxGroup defaultValue={checkboxFilters}>
              <Stack spacing="4">
                <Stack spacing="2">
                  <Text color="gray.600" fontSize="sm" fontWeight="500">
                    {t('Step', { nb: 1 })}
                  </Text>
                  <Divider />
                  <SimpleGrid gap="4" minChildWidth="250px" pb="4" pt="2">
                    {subscriptionFiltersStep.map((filter) => (
                      <Checkbox
                        key={filter.name}
                        alignItems="flex-start"
                        as={HStack}
                        lineHeight="1"
                        spacing="1"
                        {...checkboxGroup.getCheckboxProps({ value: filter.value })}
                      >
                        {getFilterLabel(filter.name)}
                      </Checkbox>
                    ))}
                  </SimpleGrid>
                </Stack>
                <Stack spacing="2">
                  <Text color="gray.600" fontSize="sm" fontWeight="500">
                    {t('InvestmentSupport')}
                  </Text>
                  <Divider />
                  <SimpleGrid gap="4" minChildWidth="250px" pb="4" pt="2">
                    {subscriptionFilterHoldingMethod.map((filter) => {
                      return (
                        <Checkbox
                          key={filter.name}
                          alignItems="flex-start"
                          as={HStack}
                          lineHeight="1"
                          spacing="1"
                          {...checkboxGroup.getCheckboxProps({ value: filter.value })}
                        >
                          {getFilterLabel(filter.name)}
                        </Checkbox>
                      );
                    })}
                  </SimpleGrid>
                </Stack>
                <Stack spacing="2">
                  <Text color="gray.600" fontSize="sm" fontWeight="500">
                    {t('SellerQuality')}
                  </Text>
                  <Divider />
                  <SimpleGrid gap="4" minChildWidth="250px" pb="4" pt="2">
                    {sellerSubscriptionFilterIdentity.map((filter) => (
                      <Checkbox
                        key={filter.name}
                        alignItems="flex-start"
                        as={HStack}
                        lineHeight="1"
                        spacing="1"
                        {...checkboxGroup.getCheckboxProps({ value: filter.value })}
                      >
                        {getFilterLabel(filter.name)}
                      </Checkbox>
                    ))}
                  </SimpleGrid>
                </Stack>
                <Stack spacing="2">
                  <Text color="gray.600" fontSize="sm" fontWeight="500">
                    {t('BuyerQuality')}
                  </Text>
                  <Divider />
                  <SimpleGrid gap="4" minChildWidth="250px" pb="4" pt="2">
                    {buyerSubscriptionFilterIdentity.map((filter) => (
                      <Checkbox
                        key={filter.name}
                        alignItems="flex-start"
                        as={HStack}
                        lineHeight="1"
                        spacing="1"
                        {...checkboxGroup.getCheckboxProps({ value: filter.value })}
                      >
                        {getFilterLabel(filter.name)}
                      </Checkbox>
                    ))}
                  </SimpleGrid>
                </Stack>
                <Stack spacing="2">
                  <Text color="gray.600" fontSize="sm" fontWeight="500">
                    {t('Tags')}
                  </Text>
                  <Divider />
                  <AsyncSelect
                    defaultOptions
                    isMulti
                    isSearchable
                    components={{ MultiValue: TagMultiValue, Input: TagInput }}
                    defaultValue={tagFilterOptions}
                    isClearable={false}
                    loadOptions={loadTagOptions}
                    menuPlacement="top"
                    noOptionsMessage={getNoOptionsMessage}
                    placeholder={t('SelectTags')}
                    formatOptionLabel={(option: TagOption): JSX.Element => (
                      <Badge colorScheme={option.color}>{option.label}</Badge>
                    )}
                    onChange={handleTagChange}
                  />
                </Stack>
              </Stack>
            </CheckboxGroup>
          </ModalBody>
          <Divider />
          <ResponsiveModalFooter>
            <Button type="button" variant="secondary" onClick={handleFilterModalClose}>
              {t('Cancel')}
            </Button>
            <Button form="share-preference" type="submit" onClick={handleFilterModalSubmit}>
              {t('Validate')}
            </Button>
          </ResponsiveModalFooter>
        </ModalContent>
      </ResponsiveModal>
    </>
  );
}
