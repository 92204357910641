import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useGetCompanyQuery } from '@blockpulse3/graphql/hooks';
import { CompleteCompanyForm } from '@blockpulse3/web-client/workspace';

/**
 * RepatriationShareTransferRegisterCompany.
 * Complete company informations before repatriate it.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function RepatriationShareTransferRegisterCompany(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const navigate = useNavigate();

  /* ** From cache ** */
  const { data } = useGetCompanyQuery({ variables: { companyId }, skip: !companyId });
  const company = data?.company;

  const handleFormSubmit = (): void => {
    navigate(routes.company.repatriation.assets.href);
  };

  const handleStepCancel = (): void => {
    navigate(generatePath(routes.company.href, { companyId }));
  };

  const companyName = company?.name;

  return (
    <Card variant="divider-top" width="full">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{t('CompleteCompanyInfoOf', { companyName })}</Heading>
        <Text color="gray.600" fontSize="lg">
          {t('CompleteCompanyInfoDescription')}
        </Text>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        <CompleteCompanyForm onSubmit={handleFormSubmit} />
      </CardBody>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button variant="secondary" w="full" onClick={handleStepCancel}>
          {t('BackToDashboard')}
        </Button>
        <Button form="complete-company" type="submit" w="full">
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}
