import { Badge, Button, Card, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  AssetEntityInfosFragment,
  AssetInfosFragment,
  AssetStatus,
  AssetType,
} from '@blockpulse3/graphql/hooks';
import { ASSET_COLOR_MAP, AssetBadge } from '@blockpulse3/ui/commons';

type Props = {
  asset: AssetEntityInfosFragment | AssetInfosFragment;
  onClick?: (asset: AssetEntityInfosFragment | AssetInfosFragment) => void;
};

export function AssetCard({ asset, onClick = noop }: Props): JSX.Element {
  const t = useTranslations();
  const i18nAssetType = useTranslations('AssetTypeValues');

  const handleAssetClick = (): void => {
    onClick(asset);
  };

  return (
    <Card
      key={asset.id}
      borderBottomWidth="2px"
      borderColor={ASSET_COLOR_MAP[asset.assetType]}
      borderStyle="solid"
      justifyContent="space-between"
      maxH="150px"
      maxW="180px"
      minH="150px"
      minW="180px"
      overflow="hidden"
      p="3"
      onClick={handleAssetClick}
    >
      <Stack m="-3" p="3" pl="4" spacing="1">
        <AssetBadge
          assetName={asset.name}
          boxed={false}
          size="lg"
          type={asset.assetType}
          value={asset.supply}
          assetCount={
            ('token' in asset &&
              asset.token &&
              'grants' in asset.token &&
              asset.token?.grants?.length) ||
            undefined
          }
        />
        <HStack justifyContent="space-between">
          <Text color="gray.500" fontSize="xs" fontWeight="400">
            {i18nAssetType(asset.assetType, { nb: 1 })}
          </Text>
          {asset.status === AssetStatus.DRAFT && (
            <Badge colorScheme="gray" fontSize="xs">
              {t('Draft', { nb: 1 })}
            </Badge>
          )}
        </HStack>
      </Stack>
      {(asset.assetType !== AssetType.BOND || ('operation' in asset && asset.operation?.id)) && (
        <Button rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="3" />} size="xs" variant="link">
          {t('ShowDetails')}
        </Button>
      )}
    </Card>
  );
}
