import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { OperationStatus } from '@blockpulse3/graphql/hooks';

import { OperationRow } from '../../types';
import { OperationCardTemplate } from './OperationCardTemplate';

type Props = {
  operation: OperationRow;
};

export function OperationCardOptionPool({ operation }: Props): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const handleOperationDraftClick = (): void => {
    if (!companyId) return;
    // FILL DRAFT OPTION POOL ROUTE HERE
  };

  const handleOperationCurrentClick = (): void => {
    if (companyId) {
      // FILL OPTION POOL ROUTE HERE
    }

    return;
  };

  const handleRowClick = (): void => {
    switch (operation.status) {
      case OperationStatus.DRAFT: {
        handleOperationDraftClick();
        break;
      }

      default: {
        handleOperationCurrentClick();
        break;
      }
    }
  };

  return (
    <OperationCardTemplate
      operation={operation}
      operationSubtitle={t('OptionPool')}
      onClick={handleRowClick}
    />
  );
}
