import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { AssetType } from '@blockpulse3/data/shared';
import {
  AssetEntityInfosFragment,
  useCreateOrUpdateAgaAssetMutation,
} from '@blockpulse3/graphql/hooks';
import { ResponsiveModalProps, useErrorToast } from '@blockpulse3/ui/commons';

import { OptionCreateModal } from './OptionCreateModal';
import { IOptionAsset } from './types';

type Props = {
  editAsset?: AssetEntityInfosFragment | null;
  onClose: () => void;
  onComplete: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

export function AGACreateModal({ editAsset, onClose, onComplete, ...props }: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { companyId = '' } = useParams();
  const [createOrUpdateAGAAsset, { loading: isCreateLoading }] =
    useCreateOrUpdateAgaAssetMutation();

  const handleModalClose = (): void => {
    onClose();
  };

  const handleFormSubmit: SubmitHandler<IOptionAsset> = (data): void => {
    if (!companyId) return;

    const {
      hasCurve,
      cliffDuration,
      vestingDuration,
      intervalDuration,
      subscriptionPrice,
      exercisePrice,
      ...optionData
    } = data;

    const optionAsset = {
      ...optionData,
      assetId: editAsset?.id,
      assetType: AssetType.AGA,
      durationUnit: data.durationUnit.value,
      vestingCurve: {
        noCurve: !hasCurve,
        cliffDuration,
        vestingDuration,
        intervalDuration,
      },
    };

    createOrUpdateAGAAsset({
      variables: {
        companyId,
        createOrUpdateAGAAssetInput: {
          ...optionAsset,
        },
      },
      onCompleted: () => {
        onComplete();
        handleModalClose();
      },
      onError: () => {
        errorToast({ title: t('ErrorAddingOption') });
        handleModalClose();
      },
    });
  };

  return (
    <OptionCreateModal
      assetType={AssetType.AGA}
      attributionPeriodRequired={false}
      editAsset={editAsset}
      exercisePriceRequired={false}
      handleFormSubmit={handleFormSubmit}
      isLoading={isCreateLoading}
      modalSubmitText={t(editAsset ? 'UpdateAGA' : 'AddTheAGA')}
      modalTitle={t(editAsset ? 'UpdateAGA' : 'AddExistingAGA')}
      subscriptionPeriodRequired={false}
      subscriptionPriceRequired={false}
      onClose={handleModalClose}
      {...props}
    />
  );
}
