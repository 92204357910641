import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { AssetType } from '@blockpulse3/data/shared';
import {
  AssetEntityInfosFragment,
  useCreateOrUpdateBsaAssetMutation,
} from '@blockpulse3/graphql/hooks';
import { ResponsiveModalProps, useErrorToast } from '@blockpulse3/ui/commons';

import { OptionCreateModal } from './OptionCreateModal';
import { IOptionAsset } from './types';

type Props = {
  editAsset?: AssetEntityInfosFragment | null;
  onClose: () => void;
  onComplete: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

export function BSACreateModal({ editAsset, onClose, onComplete, ...props }: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { companyId = '' } = useParams();
  const [createOrUpdateBSAAsset, { loading: isCreateLoading }] =
    useCreateOrUpdateBsaAssetMutation();

  const handleModalClose = (): void => {
    onClose();
  };

  const handleFormSubmit: SubmitHandler<IOptionAsset> = (data): void => {
    if (!companyId) return;

    const { hasCurve, cliffDuration, vestingDuration, intervalDuration, ...optionData } = data;

    const optionAsset = {
      ...optionData,
      assetId: editAsset?.id,
      assetType: AssetType.BSA,
      durationUnit: data.durationUnit.value,
      vestingCurve: {
        noCurve: !hasCurve,
        cliffDuration,
        vestingDuration,
        intervalDuration,
      },
    };

    createOrUpdateBSAAsset({
      variables: {
        companyId,
        createOrUpdateBSAAssetInput: {
          ...optionAsset,
        },
      },
      onCompleted: () => {
        onComplete();
        handleModalClose();
      },
      onError: () => {
        errorToast({ title: t('ErrorAddingOption') });
        handleModalClose();
      },
    });
  };

  return (
    <OptionCreateModal
      assetType={AssetType.BSA}
      editAsset={editAsset}
      handleFormSubmit={handleFormSubmit}
      isLoading={isCreateLoading}
      modalSubmitText={t(editAsset ? 'UpdateBSA' : 'AddTheBSA')}
      modalTitle={t(editAsset ? 'UpdateBSA' : 'AddExistingBSA')}
      onClose={handleModalClose}
      {...props}
    />
  );
}
